@CHARSET "UTF-8";

/*
	table-definitions
	@extends #cal .day
*/

tbody .fc-sat,
tbody .fc-sun {
  border: 1px solid #e3e3e3;
  background-color: #f8f8f8 !important;
}

/*

tr.current_week > td{
	border-top: 2px solid #000 !important;
	border-bottom: 2px solid #000 !important;
}
tr.current_week > td:last-child{
	border-right: 2px solid #000 !important;
}
tr.current_week > td:first-child{
	border-left: 2px solid #000 !important;
}

/*
	Datumsangabe
	@extends #cal .day

*/
.fc-grid table tr td a.new-event-button,
.fc-grid table tr td a.new-ressource-button {
  float: right;
  display: none;
  margin-right: 0px;
}

.fc-grid table tr td:hover a.new-event-button,
.fc-grid table tr td:hover a.new-ressource-button {
  display: block;
}

div.fc-day-number {
  line-height: 2em;
  margin: 0 7px;
}

.fc-week-number {
  color: #ccc;
  padding-left: 8px;
}

a.new-event-button,
a.new-ressource-button {
  background-image: url("../img/icons/14/buttons/icn_plus.png");
  display: block;
  height: 16px;
  overflow: hidden;
  white-space: nowrap;
  width: 16px;
  border-color: #E3E3E3 #4D4D4D #4D4D4D #E3E3E3;
  border-style: solid;
  border-width: 1px;
  color: #000000 !important;
  text-decoration: none !important;
  text-indent: 50px;
  margin-top: 2px;
  margin-right: -2px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
}

/*
.fc-other-month > .fc-day-number,
.fc-week-number {
	color: #999;
}

.fc-week-number {
        font-size: 0.9em;
}

/*
	border definitions fuer table

#cal .item{
	position: static;
}


#cal .toolbar{
	float:right;
	position: absolut;
	top: 0px;
	right: 2px;
}

/*
	Termine + Labels
	@extends #cal .day
*/
/*


.fc-event{
	border-top:1px solid #fff;
	overflow:hidden !important;
}

.fc-event .readonly{
	color: #000;
	margin:0 0 0 15px !important;
	display: block;
	padding: 0 2px;
}

.fc-event a{
	height: 18px;
	display: block;

}

.fc-event a.links	{
	background:#fff url(../img/termin.png) repeat-x right top !important;
	margin-left: 15px;

}

.fc-event a.readonly	{
	background:url("../img/termin.png") repeat-x scroll right top transparent;
	height:18px;
	padding-left:5px;
	padding-top:2px;
}


.fc-event.allday a.links{
	background-color: inherit !important;
}

/*
	tooltips
	@extends .day .fc-event
*/
#tooltip {
  border: 1px solid #AAAAAA;
  width: 350px;
  min-height: 250px;
  text-align: left;
  padding: 10px 20px 0;
  line-height: 18px;
  font-size: 12px;
  background: #fff;
  color: #000 !important;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
}

#tooltip-container {
  display: none;
}

#tooltip h2 {
  margin: 10px 10px 20px 0;
}

#tooltip ul {
  list-style: none;
  margin: 0 10px;
}

#tooltip ul.links {
  list-style: none;
  margin: 0 10px 0 0px;
  padding-top: 10px;
}

#tooltip a {
  display: inline-block;
}

#tooltip li {
  display: inline-block;
  margin-left: 100px;
}

#tooltip ul.links li {
  margin-left: 0px;
}

#tooltip li span {
  width: 100px;
  display: inline-block;
  font-weight: 600;
  margin-left: -110px;
}

#tooltip table {
  margin: 0
}

#tooltip table tr th {
  width: 110px;
}

#tooltip .toolbar li:first-child {
  margin-left: 75px;
}

#tooltip .toolbar li {
  margin-left: 10px;
}

/*
	FullCalendar Styling
	@extends
*/
h1 table,
.fc-view-month table {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  text-transform: none;
}

h2.fc-header-title {
  margin-left: 0;
  margin-right: 0;
  font-size: 1.6364em;
  font-weight: normal;
  line-height: 1.2222em;
  text-transform: none;
}

/*
* NEW CALENDAR CSS *
*


 * FullCalendar v1.4.6 Stylesheet
 *
 * Feel free to edit this file to customize the look of FullCalendar.
 * When upgrading to newer versions, please upgrade this file as well,
 * porting over any customizations afterwards.
 *
 * Date: Mon May 31 10:18:29 2010 -0700
*/

.fc,
.fc .fc-header,
.fc .fc-content {
  font-size: 1em;
}

.fc {
  direction: ltr;
  text-align: left;
}

.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
}

.fc td, .fc th {
  padding: 0;
  margin: 0;
  vertical-align: top;
}

/*
 Header
------------------------------------------------------------------------
*/

table.fc-header {
  width: 100%;
  margin-bottom: 10px;
}

.fc-header-left {
  width: 25%;
  padding-left: 15px !important;
}

.fc-header-left table {
  float: left;
  margin-top: 50px;
}

.fc-header-center {
  width: 50%;
}

.fc-header-center table {
  display: block;
  position: absolute;
  top: 180px;
  left: 278px;
}

.fc-header-right {
  width: 25%;
  padding-right: 20px !important;
}

.fc-header-right table {
  float: right;
  margin-top: 50px;
}

.fc-header-title {
  margin-top: 0;
  white-space: nowrap;
}

.fc-header-space {
}

.fc-rtl .fc-header-title {
  direction: rtl;
}

/*
 Buttons
------------------------------------------------------------------------
*/

.fc-header .fc-state-default,
.fc-header .ui-state-default {
  margin-bottom: 1em;
  cursor: pointer;
}

.fc-header .fc-state-default {
  border-width: 0;
  margin: 0 5px;
  padding: 0;
}

.fc-header .fc-state-default a {
  display: block;
  margin: 0;
  width: 100%;
  text-decoration: none;
}

.fc-header .fc-state-default span {
  display: block;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  padding: 0 13px;
}

.fc-header .fc-button-prev span {
  padding: 0 3px 0 0 !important;
}

.fc-header .fc-button-next span {
  padding: 0 0 0 3px !important;
}

.fc-header .ui-state-default {
  padding: 4px 6px;
}

.fc-header .fc-state-default span,
.fc-header .ui-state-default span {
  white-space: nowrap;
}

/*
 * for adjacent buttons
 */
.fc-header .fc-no-right {
  padding-right: 0;
}

.fc-header .fc-no-right a {
  margin-right: 0;
  border-right: 0;
}

.fc-header .ui-no-right {
  border-right: 0;
}

/*
 * for fake rounded corners
 */
.fc-header .fc-corner-left {
  padding-left: 0;
}

.fc-corner-left {
}

.fc-header .fc-corner-right {

  padding-right: 0;
}

/*
 * DEFAULT button COLORS
 */
.fc-header .fc-state-default,
.fc-header .fc-state-default a {
  /* border-color: #777;  outer border */
  color: #333;
}

.fc-header .fc-state-default span {
  border-color: #E3E3E3 #4D4D4D #4D4D4D #E3E3E3;
  border-style: solid;
  border-width: 1px;
  color: #000000 !important;
  background: url("../img/tiles-buttons.png") no-repeat scroll 0 center #fff;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

/*
 PRESSED button COLORS (down and active)
*/

.fc-header .fc-state-active a {
  color: #fff;
}

.fc-header .fc-state-down span,
.fc-header .fc-state-active span {
  -moz-border-radius: 3px 3px 3px 3px;
  border-color: #6e6e6e #f1f1f1 #f1f1f1 #6e6e6e;
  border-style: solid;
  border-width: 1px;
  color: #fff !important;
  background: url("../img/tiles-buttons.png") no-repeat scroll 0 center #ccc;
}

/*
 DISABLED button COLORS
*/

.fc-header .fc-state-disabled a {
  color: #999;
}

.fc-header .fc-state-disabled,
.fc-header .fc-state-disabled a {
  border-color: #ccc; /* outer border */
}

.fc-header .fc-state-disabled span {
  border-color: #6e6e6e #f1f1f1 #f1f1f1 #6e6e6e;
  border-style: solid;
  border-width: 1px;
  color: #fff !important;
  background: url("../img/tiles-buttons.png") no-repeat scroll 0 center #ccc;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

/*
 Content Area & Global Cell Styles
------------------------------------------------------------------------
*/

.fc-widget-content {
  border: 1px solid #ccc; /* outer border color */
}

.fc-content {
  clear: both;
  -moz-box-shadow: 0 3px 10px #C6C6C6;
  -webkit-box-shadow: 0 3px 10px #C6C6C6;
}

.fc-content .fc-state-default {
  border-style: solid;
  border-color: #ccc; /* inner border color */
}

.fc-content .fc-state-highlight {
  /* today */
  background: #ffc;
  border-color: #000000;
  border-width: 1px !important;
}

.fc-content .fc-not-today {
  background: none;
}

.fc-cell-overlay {
  /* semi-transparent rectangle while dragging */
  background: #9cf;
  opacity: .2;
  filter: alpha(opacity=20); /* for IE */
}

.fc-view {
  /* prevents dragging outside of widget*/
  width: 100%;
  overflow: hidden;
}

/*
 Global Event Styles
------------------------------------------------------------------------
*/

.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
  color: #000; /* default TEXT color */
}

.fc-event {
  text-align: left;
  min-height: 16px !important;
}

.fc-view-month .fc-event {
  max-height: 16px !important;
  overflow: hidden;
}

.fc-view-agendaWeek .fc-agenda-body .fc-event {
  margin: 0 0 0 -2px;
  padding-top: 2px;
}

.fc-event a {
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
}

.fc-event-editable {
  cursor: pointer;
}

.fc-view-month .eventinfo {
  background-color: #fff;
  padding: 0 3px 0 3px;
  margin-left: 18px !important;
}

.allday .eventinfo {
  background-color: transparent !important;
  margin-left: 2px;
}

.calendar-1 .eventinfo,
.calendar-2 .eventinfo,
.calendar-3 .eventinfo,
.calendar-4 .eventinfo,
.calendar-5 .eventinfo,
.calendar-6 .eventinfo,
.calendar-7 .eventinfo,
.calendar-8 .eventinfo,
.calendar-9 .eventinfo,
.calendar-10 .eventinfo,
.calendar-11 .eventinfo {
    padding-left:18px;
    margin-left: 3px !important;
}

.fc-event-time,
.fc-event-title {
  padding: 0 2px;
}

/* for fake rounded corners */

.fc-event a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

/* right-to-left */

.fc-rtl .fc-event a {
  text-align: right;
}

/* resizable */

.fc .ui-resizable-handle {
  display: block;
  position: absolute;
  z-index: 99999;
  border: 0 !important; /*important overrides pre jquery ui 1.7 styles*/
  background: url(data:image/gif;base64,AAAA) !important; /*hover fix for IE*/
}

/*
 Horizontal Events
------------------------------------------------------------------------
*/

.fc-event-hori {
  border-width: 1px 0;
}

.fc-event-hori a {
  border-width: 0;
}

/* resizable */

.fc-event-hori .ui-resizable-e {
  top: 0 !important; /* importants override pre jquery ui 1.7 styles */
  right: -3px !important;
  width: 7px !important;
  height: 100% !important;
  cursor: e-resize;
}

.fc-event-hori .ui-resizable-w {
  top: 0 !important;
  left: -3px !important;
  width: 7px !important;
  height: 100% !important;
  cursor: w-resize;
}

.fc-event-hori .ui-resizable-handle {
  _padding-bottom: 14px; /* IE6 had 0 height */
}

/*
 Month View, Basic Week View, Basic Day View
------------------------------------------------------------------------
*/

.fc-agenda-body {
  height: auto !important;
}

.fc-grid table {
  width: 100%;
}

.fc .fc-grid th {
  border-width: 0 0 0 1px;
  text-align: center;
}

.fc .fc-grid td {
  border-width: 1px 0 0 1px;
}

.fc-grid th.fc-leftmost,
.fc-grid td.fc-leftmost {
  border-left: 0;
}

.fc-grid .fc-day-number {
  float: left;
  padding: 0 2px;
  display: block;
  width: 94%;
}

.fc-grid .fc-other-month .fc-day-number {
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* for IE
      opacity with small font can sometimes look too faded
        might want to set the 'color' property instead
        making day-numbers bold also fixes the problem*/
}

.fc-grid .fc-day-content {
  clear: both;
  padding: 2px 2px 0;
}

/**
 * this will make the content area too high, that the calendar week will be pushed out of the table cell.
 */
.fc-view-month .fc-day-content {
  min-height: 82%;
}

/* event styles */

.fc-grid .fc-event-time {
  font-weight: bold;
}

/*right-to-left */

.fc-rtl .fc-grid {
  direction: rtl;
}

.fc-rtl .fc-grid .fc-day-number {
  float: left;
}

.fc-rtl .fc-grid .fc-event-time {
  float: right;
}

/*
 Agenda Week View, Agenda Day View
------------------------------------------------------------------------
*/
.fc .fc-agenda th,
.fc .fc-agenda td {
  border-width: 1px 0 0 1px;
}

.fc .fc-agenda .fc-leftmost {
  border-left: 0;
}

.fc-agenda tr.fc-first th,
.fc-agenda tr.fc-first td {
  border-top: 0;
}

.fc-agenda-head tr.fc-first th {
  border-bottom-width: 1px;
  background-color: #000 !important;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
}

.fc-agenda-head tr.fc-last th {
  border-bottom-width: 1px;
}

.fc .fc-agenda-head td,
.fc .fc-agenda-body td {
  background: none;
}

.fc-agenda-head th {
  text-align: center;
}

/* the time axis running down the left side */

.fc-agenda .fc-axis {
  width: 50px;
  padding: 0 4px;
  vertical-align: middle;
  white-space: nowrap;
  text-align: right;
  font-weight: normal;
}

/* all-day event cells at top */

.fc-agenda-head tr.fc-all-day th {
  height: 35px;
}

.fc-agenda-head td {
  padding-bottom: 10px;
}

.fc .fc-divider div {
  font-size: 1px; /* for IE6/7 */
  height: 2px;
}

.fc .fc-divider .fc-state-default {
  background: #eee; /* color for divider between all-day and time-slot events */
}

/*body styles */

.fc .fc-agenda-body td div {
  height: 20px; /* slot height */
}

.fc .fc-agenda-body tr.fc-minor th,
.fc .fc-agenda-body tr.fc-minor td {
  border-top-style: dotted;
}

.fc-agenda .fc-day-content {
  padding: 2px 2px 0; /* distance between events and day edges */
}

/*
 Vertical Events
------------------------------------------------------------------------
*/

.fc-event-vert {
  border-width: 0 1px;
}

.fc-view-agendaWeek .fc-event-vert {
  margin-left: -2px;
  /* padding-right:25px; */
  padding-bottom: 2px;
}

.fc-view-agendaDay .fc-agenda-body .fc-event-vert {
  margin-left: -2px;
  /* padding-right:25px; */
  padding-bottom: 2px;
}

.fc-event-vert a {
  border-width: 0;
}

/*for fake rounded corners */

.fc-content .fc-corner-top {
  margin-top: 1px;
}

.fc-content .fc-corner-top a {
  margin-top: -1px;
  border-top-width: 1px;
}

.fc-content .fc-corner-bottom {
  margin-bottom: 1px;
}

.fc-content .fc-corner-bottom a {
  margin-bottom: -1px;
  border-bottom-width: 1px;
}

/* event content */

.fc-event-vert span {
  display: block;
  position: relative;
  z-index: 2;
}

.fc-event-vert span.fc-event-time {
  white-space: nowrap;
  _white-space: normal;
  overflow: hidden;
  border: 0;
  font-weight: bold;
  display: inline;
}

.fc-event-vert span.fc-event-title {
  line-height: 13px;
  display: inline;
}

.fc-event-vert span.fc-event-bg {
  /* makes the event lighter w/ a semi-transparent overlay */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  /* width: 100%;
height: 20px;
background: #fff;
opacity: .3;
filter: alpha(opacity=30);  /* for IE */

}

/* resizable */

.fc-event-vert .ui-resizable-s {
  bottom: 0 !important; /* importants override pre jquery ui 1.7 styles */
  width: 100% !important;
  height: 8px !important;
  line-height: 8px !important;
  font-size: 11px !important;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}

ul.mycalendars > li .item-title {
  padding-left: 5px;
}

#msg-container {
  position: absolute;
  top: 219px;
  right: 0;
  z-index: 59;
  text-align: center;
}

#msg-container .inner-content {
  margin: 0 auto;
  width: 360px;
  height: 32px;
  background: #fff;
  border: 1px solid #FFC600;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
  padding-top: 3px;
}

.calendar-1,
.calendar-2,
.calendar-3,
.calendar-4,
.calendar-5,
.calendar-6,
.calendar-7,
.calendar-8,
.calendar-9,
.calendar-10,
.calendar-11 {
    background-image:url(../img/schraffur.png);
}

#tempCalenderContainer {
    display: none;
}
