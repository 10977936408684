button, input[type="submit"], input[type="reset"], input[type="button"], .editing a[rel], a.button {
  border-radius: 3px;
  -khtml-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -webkit-border-radius: 3px;
}

/*
	--- edit ---
*/
button.rel.edit,
.toolbar a[rel="edit"],
.toolbar a[rel="newTimesheet"],
.toolbar a.edit {
  background-image: url(../img/icons/14/buttons/icn_edit.png);
}

/*
	--- copy ---
*/
button.rel.copy,
.toolbar a[rel="copy"] {
  background-image: url(../img/icons/14/buttons/icn_duplicatedoc.png);
}

/*
	--- delete ---
*/
button.rel.delete,
.toolbar a[rel="delete"],
.toolbar a[rel="deleterepeatevent"],
.toolbar a.delete {
  background-image: url(../img/icons/14/buttons/icn_delete.png);
}

/*
	--- remove ---
*/
button.rel.remove,
.toolbar a[rel="remove"] {
  background-image: url(../img/icons/14/buttons/icn_minus.png);
}

/*
	--- close ---
*/
button.rel.close,
.toolbar a[rel="close"] {
  background-image: url(../img/icons/14/buttons/icn_cross.png);
}

/*
	--- new ---
*/
button.rel.new,
.toolbar a[rel="new"] {
  background-image: url(../img/icons/14/buttons/icn_add.png);
}

/*
	--- zip ---
*/
button.rel.zip,
.toolbar a[rel="zip"] {
  background-image: url(../img/icons/14/buttons/icn_down.png);
}

/*
    --- disabled button ---
*/
button[disabled],
.toolbar button[disabled] {
  background-image: url(../img/icons/14/buttons/icn_undeletable.png);
  background-position-x: 1px;
}

/*
	--- add ---
*/
button.rel.add,
.toolbar a[rel="add"] {
  background-image: url(../img/icons/14/buttons/icn_plus.png);
}

button.rel.add.forecast,
.toolbar a[rel="add"].forecast,
.toolbar a[rel="add_redirect"].forecast {
  background-image: url(../img/icons/14/buttons/icn_addforecast.png);
}

button.rel.add.invoice,
.toolbar a[rel="add"].invoice,
.toolbar a[rel="add_redirect"].invoice {
  background-image: url(../img/icons/14/buttons/icn_addinvoice.png);
}

button.rel.add.credit,
.toolbar a[rel="add"].credit,
.toolbar a[rel="add_redirect"].credit {
  background-image: url(../img/icons/14/buttons/icn_addcredit.png);
}

button.rel.add.proposal,
.toolbar a[rel="add"].proposal,
.toolbar a[rel="add_redirect"].proposal {
  background-image: url(../img/icons/14/buttons/icn_addproposal.png);
}

button.rel.add.expense,
.toolbar a[rel="add"].expense,
.toolbar a[rel="add_redirect"].expense {
  background-image: url(../img/icons/14/buttons/icn_addexpense.png);
}

button.rel.add.dunning,
.toolbar a[rel="add"].dunning {
  background-image: url(../img/icons/14/buttons/icn_adddunning.png);
}

button.rel.add.cancelation,
.toolbar a[rel="add"].cancelation {
  background-image: url(../img/icons/14/buttons/icn_addcancelation.png);
}

button.rel.add.shipping,
.toolbar a[rel="add"].shipping {
  background-image: url(../img/icons/14/buttons/icn_addshipping.png);
}

/*
	--- assign ---
*/
button.rel.list,
.toolbar a[rel="list"] {
  background-image: url(../img/icons/14/buttons/icn_plus.png);
}

/*
	--- print ---
*/
button.rel.print,
.toolbar a[rel="print"] {
  background-image: url(../img/icons/14/buttons/icn_print_plus.png);
}

button.rel.print-no-letterhead,
.toolbar a[rel="print-no-letterhead"] {
  background-image: url(../img/icons/14/buttons/icn_print.png);
}

/*
	--- export / download ---
*/
button.rel.export,
.toolbar a[rel="export"] {
  background-image: url(../img/icons/14/buttons/icn_down.png);
}

/*
	--- vcard export / download ---
*/
button.rel.export,
.toolbar a[rel="export-vcard"] {
    background-image: url(../img/icons/14/buttons/icn_export_vcard.png);
}

/*
	--- subscribe ---
*/
button.rel.subscribe,
.toolbar a[rel="subscribe"] {
  background-image: url(../img/icons/14/buttons/icn_bookmark.png);
}

/*
	--- unsubscribe ---
*/
button.rel.subscribe,
.toolbar a[rel="unsubscribe"] {
  background-image: url(../img/icons/14/buttons/icn_removebookmark.png);
}

/*
	--- comment ---
*/
button.rel.comment,
.toolbar a[rel="comment"] {
  background-image: url(../img/icons/14/buttons/icn_comment.png);
}

/*
	--- arrow: first ---
*/
button.rel.first,
.toolbar a[rel="first"] {
  background-image: url(../img/icons/14/buttons/arrow_fastbk.png);
}

button.rel.first[disabled],
.toolbar a[rel="first"].disabled {
  background-image: url(../img/icons/14/buttons/d_arrow_fastbk.png);
}

/*
	--- arrow: previous ---
*/
button.rel.prev,
.toolbar a[rel="prev"] {
  background-image: url(../img/icons/14/buttons/arrow_bk.png);
}

button.rel.prev[disabled],
.toolbar a[rel="prev"].disabled {
  background-image: url(../img/icons/14/buttons/d_arrow_bk.png);
}

/*
	--- arrow: next ---
*/
button.rel.next,
.toolbar a[rel="next"] {
  background-image: url(../img/icons/14/buttons/arrow_frw.png);
}

button.rel.last,
button.rel.first,
button.rel.prev,
button.rel.next {
  text-indent: -9999px;
}

button.rel.next[disabled],
.toolbar a[rel="next"].disabled {
  background-image: url(../img/icons/14/buttons/d_arrow_frw.png);
}

/*
	--- arrow: last ---
*/
button.rel.last,
.toolbar a[rel="last"] {
  background-image: url(../img/icons/14/buttons/arrow_fastfrw.png);
}

button.rel.last[disabled],
.toolbar a[rel="last"].disabled {
  background-image: url(../img/icons/14/buttons/d_arrow_fastfrw.png);
}

/*
 --- arrow: up
*/
button.rel.up,
.toolbar a[rel="up"] {
  background-image: url(../img/icons/14/buttons/arrow_up.png);
}

button.rel.up[disabled],
.toolbar a[rel="up"].disabled {
  background-image: url(../img/icons/14/buttons/d_arrow_up.png);
}

/*
 --- arrow: down
*/
button.rel.down,
.toolbar a[rel="down"] {
  background-image: url(../img/icons/14/buttons/arrow_down.png);
}

button.rel.down[disabled],
.toolbar a[rel="down"].disabled {
  background-image: url(../img/icons/14/buttons/d_arrow_down.png);
}

/*
	--- move ---
*/
button.rel.move,
.toolbar a[rel="move"] {
  background-image: url(../img/icons/14/buttons/icn_move.png);
  cursor: move;
  float: left;
  position: relative;
}

/*
	--- load snippets ---
*/
button.rel.snippet {
  background-image: url(../img/icons/14/buttons/icn_snippet.png);
  min-width: 18px !important;
  float: right;
  margin-right: -19px;
  text-indent: -9999px;
  position: absolute; /*tinymce*/
  right: 45px; /*tinymce*/
  top: 62px; /*tinymce*/
}

li.wide button.rel.snippet {
  top: 72px; /*tinymce*/
}

/*
	--- timer ---
*/
button.rel.timer,
.toolbar a[rel="timer"] {
  background: url(../img/icons/14/icn_freefortimesheets.png) no-repeat 1px;
}

#prjProposalEntryForm button.rel.snippet,
#prjInvoiceEntryForm button.rel.snippet {
  top: 105px; /*tinymce*/
}

/*
	=== TOOLBAR ICONS ======================================================
*/
.toolbar-main a[rel],
.toolbar-main a.settings-ico {
  background: url(../img/icons/28/icn_toolbar.png) no-repeat 0 top;
}

.toolbar-main a[rel]:hover,
.toolbar-settings a:hover {
  background-position: 0 bottom;
}

.toolbar-main .button-group {
  padding-top: 5px;
}

.toolbar-main a[rel="new"],
.toolbar-main a[rel="newTimesheet"],
.toolbar-main a[rel="newMaterialAccounting"] {
  background-position: -31px top;
}

.toolbar-main a[rel="new"]:hover,
.toolbar-main a[rel="newTimesheet"]:hover,
.toolbar-main a[rel="newMaterialAccounting"]:hover {
  background-position: -31px bottom;
}

.toolbar-main a[rel="add"] {
  background-position: -31px top;
}

.toolbar-main a[rel="add"]:hover {
  background-position: -31px bottom;
}

.toolbar-main a[rel="delete"] {
  background-position: -62px top;
}

.toolbar-main a[rel="delete"]:hover {
  background-position: -62px bottom;
}

.toolbar-main a[rel="edit"] {
  background-position: -93px top;
}

.toolbar-main a[rel="edit"]:hover {
  background-position: -93px bottom;
}

.toolbar-main a[rel="export"] {
  background-position: -124px top;
}

.toolbar-main a[rel="export"]:hover {
  background-position: -124px bottom;
}

.toolbar-main a[rel="export-vcard"] {
    background-position: -124px top;
}

.toolbar-main a[rel="export-vcard"]:hover {
    background-position: -124px bottom;
}

.toolbar-main a[rel="print"] {
  background-position: -372px top;
}

.toolbar-main a[rel="print"]:hover {
  background-position: -372px bottom;
}

.toolbar-main a.no-letterhead[rel="print"] {
  background-position: -155px top;
}

.toolbar-main a.no-letterhead[rel="print"]:hover {
  background-position: -155px bottom;
}

.toolbar-main a[rel="new"].person {
  background-position: -186px top;
}

.toolbar-main a[rel="new"].person:hover {
  background-position: -186px bottom;
}

.toolbar-main a[rel="new"].company {
  background-position: -217px top;
}

.toolbar-main a[rel="new"].company:hover {
  background-position: -217px bottom;
}

.toolbar-main a[rel="new"].mailing {
  background-position: -248px top;
}

.toolbar-main a[rel="new"].mailing:hover {
  background-position: -248px bottom;
}

.toolbar-main a[rel="copy"] {
  background-position: -279px top;
}

.toolbar-main a[rel="copy"]:hover {
  background-position: -279px bottom;
}

.toolbar-main a[rel="list"] {
  background-position: -310px top;
}

.toolbar-main a[rel="list"]:hover {
  background-position: -310px bottom;
}

.toolbar-main a[rel="list"].tiles {
  background-position: -341px top;
}

.toolbar-main a[rel="list"].tiles:hover {
  background-position: -341px bottom;
}

.toolbar-main a[rel="new"].cancellation,
.toolbar-main a[rel="new"].dunning,
.toolbar-main a[rel="new"].invoice,
.toolbar-main a[rel="new"].credit,
.toolbar-main a[rel="new"].proposal,
.toolbar-main a[rel="new"].shipping,
.toolbar-main a[rel="new"].expense {
  background-image: url(../img/icons/28/icn_docs.png);
}

.toolbar-main a[rel="new"].cancellation {
  background-position: -62px top;
}

.toolbar-main a[rel="new"].cancellation:hover {
  background-position: -62px bottom;
}

.toolbar-main a[rel="new"].dunning {
  background-position: -31px top;
}

.toolbar-main a[rel="new"].dunning:hover {
  background-position: -31px bottom;
}

.toolbar-main a[rel="new"].invoice {
  background-position: -124px top;
}

.toolbar-main a[rel="new"].invoice:hover {
  background-position: -124px bottom;
}

.toolbar-main a[rel="new"].credit {
  background-position: -186px top;
}

.toolbar-main a[rel="new"].credit:hover {
  background-position: -186px bottom;
}

.toolbar-main a[rel="new"].proposal {
  background-position: -93px top;
}

.toolbar-main a[rel="new"].proposal:hover {
  background-position: -93px bottom;
}

.toolbar-main a[rel="new"].expense {
  background-position: -155px top;
}

.toolbar-main a[rel="new"].expense:hover {
  background-position: -155px bottom;
}

.toolbar-main a[rel="timesheetsbyuser"] {
  background-position: -403px top;
}

.toolbar-main a[rel="timesheetsbyuser"]:hover {
  background-position: -403px bottom;
}

.toolbar-main a[rel="timesheetsbyproject"] {
  background-position: -434px top;
}

.toolbar-main a[rel="timesheetsbyproject"]:hover {
  background-position: -434px bottom;
}

.toolbar-main a[rel="email"] {
  background-position: -465px top;
}

.toolbar-main a[rel="email"]:hover {
  background-position: -465px bottom;
}

.toolbar-main a[rel="download"] {
  background-position: -124px top;
}

.toolbar-main a[rel="download"]:hover {
  background-position: -124px bottom;
}

/*
	=== TOOLBAR ICONS CAL TOOLBARTREE ======================================================
*/
.list-cal .toolbar-tree a[rel="new"],
.list-cal .toolbar-tree a[rel="new"]:hover {
  background-image: url(../img/icons/cal/cal_add.png);
  height: 12px;
  width: 12px;
}

.list-cal .toolbar-tree a[rel="edit"],
.list-cal .toolbar-tree a[rel="edit"]:hover {
  background-image: url(../img/icons/cal/cal_edt.png);
  height: 12px;
  width: 12px;
}

.list-cal .toolbar-tree a[rel="delete"],
.list-cal .toolbar-tree a[rel="delete"]:hover {
  background-image: url(../img/icons/cal/cal_del.png);
  height: 12px;
  width: 12px;
}

.list-cal .toolbar-tree a[rel="ical"],
.list-cal .toolbar-tree a[rel="ical"]:hover {
  background-image: url(../img/icons/cal/cal_ical.png);
  height: 12px;
  width: 12px;
}

/*
	=== TOOLBAR ICONS BIB TOOLBARTREE ======================================================
*/

#tree .editing a[rel="move"] {
  background-image: url(../img/icons/14/icn_move.png);
}

#tree .editing a[rel="delete"] {
  background-image: url(../img/icons/14/icn_delete.png);
}

#tree .editing a[rel="edit"] {
  background-image: url(../img/icons/14/icn_edit.png);
}

#tree .editing a[rel="new"] {
  background-image: url(../img/icons/14/icn_add.png);
}

/*
	=== #cal ICONS .day ======================================================

	--- start ---
*/
.multiday-start a.cal-item {
  background-image: url(../img/icons/cal/icn_mdstart.gif) !important;
}

/*
	--- middle ---
*/
.multiday-middle a.cal-item {
  background-image: url(../img/icons/cal/icn_mdmiddle.gif) !important;
}

/*
	--- end ---
*/
.multiday-end a.cal-item {
  background-image: url(../img/icons/cal/icn_mdstop.gif) !important;
}

/*
	--- birthday ---
*/
#cal .calendar-1 .eventinfo {
  background-image: url(../img/icons/cal/icn_birthday.gif);
  background-repeat: no-repeat;
  background-position: 5px 1px;
}

/*
	--- leave ---
*/
#cal .calendar-2 .eventinfo {
  background-image: url(../img/icons/cal/icn_leave.gif);
  background-repeat: no-repeat;
  background-position: 5px 2px;
}

/*
	--- sick ---
*/
#cal .calendar-3 .eventinfo {
  background-image: url(../img/icons/cal/icn_sick.gif);
  background-repeat: no-repeat;
  background-position: 5px 2px;
}

/*
	--- holiday ---
*/

#cal .calendar-4 .eventinfo {
  background-image: url(../img/icons/cal/icn_holiday.gif);
  background-repeat: no-repeat;
  background-position: 5px 1px;
}

/*
	--- overtime ---
*/

#cal .calendar-5 .eventinfo {
  background-image: url(../img/icons/cal/icn_overtime.gif);
  background-repeat: no-repeat;
  background-position: 5px 1px;
}

/*
	--- special leave ---
*/
#cal .calendar-6 .eventinfo {
  background-image: url(../img/icons/cal/icn_leave.gif);
  background-repeat: no-repeat;
  background-position: 5px 2px;
}

/*
	--- custom leave ---
*/
#cal .calendar-7 .eventinfo {
  background-image: url(../img/icons/cal/icn_leave.gif);
  background-repeat: no-repeat;
  background-position: 5px 2px;
}

/*
	--- milestone ---
*/
#cal .calendar-8 .eventinfo {
  background-image: url(../img/icons/cal/icn_milestone.gif);
  background-repeat: no-repeat;
  background-position: 5px 2px;
}

#cal .calendar-9 .eventinfo {
  background-image: url(../img/icons/cal/icn_remind.gif);
  background-repeat: no-repeat;
  background-position: 5px 2px;
}

#cal .calendar-10 .eventinfo {
  background: url(../img/icons/cal/icn_todo.png) no-repeat 5px 2px;
}

#cal .calendar-11 .eventinfo {
    background: url(../img/icons/cal/icn_parental_leave.gif) no-repeat 5px 2px;
}

.specials .calendar1 span,
.resources .calendar-1 .eventinfo {
  background-image: url(../img/icons/cal/icn_birthday.gif);
}

.specials .calendar2 span,
.resources .calendar-2 .eventinfo {
  background-image: url(../img/icons/cal/icn_leave.gif);
}

.specials .calendar3 span,
.resources .calendar-3 .eventinfo {
  background-image: url(../img/icons/cal/icn_sick.gif);
}

.specials .calendar4 span,
.resources .calendar-4 .eventinfo {
  background-image: url(../img/icons/cal/icn_holiday.gif);
}

.specials .calendar5 span,
.resources .calendar-5 .eventinfo {
  background-image: url(../img/icons/cal/icn_overtime.gif);
}

.specials .calendar6 span,
.resources .calendar-6 .eventinfo {
  background-image: url(../img/icons/cal/icn_leave.gif);
}

.specials .calendar7 span,
.resources .calendar-7 .eventinfo {
  background-image: url(../img/icons/cal/icn_leave.gif);
}

.specials .calendar8 span,
.resources .calendar-8 .eventinfo {
  background-image: url(../img/icons/cal/icn_milestone.gif);
}

.specials .calendar9 span,
.resources .calendar-9 .eventinfo {
  background-image: url(../img/icons/cal/icn_remind.gif);
}

.specials .calendar10 span,
.resources .calendar-10 .eventinfo {
  background-image: url(../img/icons/cal/icn_todo.png);
}

.specials .calendar11 span,
.resources .calendar-11 .eventinfo {
    background-image: url(../img/icons/cal/icn_parental_leave.gif);
}

.specials .calendar1 span,
.specials .calendar2 span,
.specials .calendar3 span,
.specials .calendar4 span,
.specials .calendar5 span,
.specials .calendar6 span,
.specials .calendar7 span,
.specials .calendar8 span,
.specials .calendar9 span,
.specials .calendar10 span,
.specials .calendar11 span {
 	background-repeat: no-repeat;
	background-position:5px 35%;
	padding:2px 0 0 20px;
 }
.resources .calendar-1,
.resources .calendar-2,
.resources .calendar-3,
.resources .calendar-4,
.resources .calendar-5,
.resources .calendar-6,
.resources .calendar-7,
.resources .calendar-8,
.resources .calendar-9,
.resources .calendar-10,
.resources .calendar-11 {

}

.resources .calendar-1 .eventinfo,
.resources .calendar-2 .eventinfo,
.resources .calendar-3 .eventinfo,
.resources .calendar-5 .eventinfo,
.resources .calendar-6 .eventinfo,
.resources .calendar-7 .eventinfo,
.resources .calendar-8 .eventinfo,
.resources .calendar-9 .eventinfo,
.resources .calendar-10 .eventinfo,
.resources .calendar-11 .eventinfo {
    background-repeat: no-repeat;
    background-position:0 3px;
    margin-left: 0 !important;
}

.resources .calendar-4 .eventinfo {
  background-repeat: no-repeat;
  background-position: 0 1px;
}

.specials .calendar1,
.specials .calendar2,
.specials .calendar3,
.specials .calendar4,
.specials .calendar5,
.specials .calendar6,
.specials .calendar7,
.specials .calendar8,
.specials .calendar9,
.specials .calendar10,
.specials .calendar11 {
 	background:url("../img/tiles-y.png") repeat-y scroll right 0 transparent;
 }
 .specials{
 background-color: #f1f1f1;
 }

.specials {
  background-color: #f1f1f1;
}

/*
	--- private ---
*/
.private {
  background-image: url(../img/icons/cal/icn_private.gif);
  background-position: 1% 50%;
  background-repeat: no-repeat;
}

/*
	=== #cal ICONS subnavi-modul ======================================================
*/

/*
	--- birthday ---
*/
ul.mycalendars > li span {
  background-repeat: no-repeat;

}

.mycalendars .calendar1 > span {
  background-image: url(../img/icons/cal/icn_birthday.gif);
  background-position: 1% top;
  padding-left: 20px;
}

/*
	--- leave ---
*/
.mycalendars .calendar2 span {
  background-image: url(../img/icons/cal/icn_leave.gif);
  background-position: 5% top;
  padding-left: 20px;
}

/*
	--- sick ---
*/
.mycalendars .calendar3 span {
  background-image: url(../img/icons/cal/icn_sick.gif);
  background-position: 2% top;
  padding-left: 20px;
}

/*
	=== #mdb #tree icons ======================================================
*/

/*
	--- single folder ---
*/
#tree span.pad {
  width: 12px;
  height: 12px;
  padding: 0 7px;
  margin: 3px 0 0 15px;
  background: url(../img/icons/14/icn_folder_single.png) -5px center no-repeat;
  background-position: left center;
}

/*
	--- multi folder ---
*/
#tree span.state, #tree span.open {
  background: url(../img/icons/14/icn_folder_multiple.png) -5px center no-repeat;
  cursor: pointer;
  background-position: left center;
}

/*
	=== PRJ#tree sub-sub-navi icons ======================================================
*/

.all_prjs a {
  background: url(../img/icons/14/icn_all_prjs.png) 20px center no-repeat !important;
}

.all_ts a {
  background: url(../img/icons/14/icn_all_ts.png) 20px center no-repeat !important;
}

.all_ma a {
  background: url(../img/icons/14/icn_all_ma.png) 20px center no-repeat !important;
}

.all_prj a:not([rel="edit"]) {
  /* nicht die ".toolbar"-Links ueberschreiben */
  background: url(../img/icons/14/icn_all_prj.png) 20px center no-repeat !important;
}

.all_bill a {
  background: url(../img/icons/14/icn_all_bill.png) 20px center no-repeat !important;
}

.all_prop a {
  background: url(../img/icons/14/icn_all_prop.png) 20px center no-repeat !important;
}

.all_inv a {
  background: url(../img/icons/14/icn_all_inv.png) 20px center no-repeat !important;
}

.all_exp a {
  background: url(../img/icons/14/icn_all_exp.png) 20px center no-repeat !important;
}

span.refresh a {
  background: url(../img/icons/14/icn_refresh.png) 15px center no-repeat;
}

span.virtualfolder a {
  background: url(../img/icons/14/icn_newest.png) 15px center no-repeat;
}

/*
	=== jQuery Icons ======================================================
*/
.ui-datepicker-prev, .ui-datepicker-next {
  text-indent: -999px;
}

/*
	===
*/

.folder-icon {
  width: 14px;
  height: 14px;
  padding: 0 7px;
  margin: 3px 0 0 7px;
  background: url(../img/icons/14/icn_folder_single.png) no-repeat;
}

.mdb-folder-icon {
  display: block;
  width: 42px;
  height: 42px;
  background: url(../img/icons/42/icn_folder.png) no-repeat;
}

.article-icon {
  width: 14px;
  height: 14px;
  padding: 0 7px;
  margin: 3px 0 0 7px;
  background: url(../img/icons/14/icn_draft.png) -1px -1px no-repeat;
}
