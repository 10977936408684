/* **************** GRIDS ***************** */
.line:after {
  content: "\00a0";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.lastUnit:after {
  /* long content needing to expand to full width */
  content: " . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
  visibility: hidden;
  clear: both;
  height: 0 !important;
  display: block;
  line-height: 0;
}

.line {
  *zoom: 1;
}

.unit {
  float: left;
}

.size1of1 {
  float: none;
}

.size1of2 {
  width: 50%;
}

.size1of3 {
  width: 33.33333%;
}

.size2of3 {
  width: 66.66666%;
}

.size1of4 {
  width: 25%;
}

.size3of4 {
  width: 75%;
}

.size1of5 {
  width: 20%;
}

.size2of5 {
  width: 40%;
}

.size3of5 {
  width: 60%;
}

.size4of5 {
  width: 80%;
}

.size1of6 {
  width: 16.66666%;
}

.size5of6 {
  width: 83.33333%;
}

.size1of8 {
  width: 12.5%;
}

.size3of8 {
  width: 37.5%;
}

.size7of8 {
  width: 87.5%;
}

.size1of16 {
  width: 6.25%;
}

.size9of16 {
  width: 56.25%;
}

.size1of24 {
  width: 4.16666%;
}

.size1of50 {
  width: 2%;
}

.minimum {
  min-width: 80px;
}

.tiny {
  //width: 5.4545em;
  width: 5em;
  max-width: 5.4545em !important;
}

.small {
  //width: 10.9091em;
  width: 11em;
  max-width: 10.9091em !important;
}

.normal {
  //width: 16.3636em;
  width: 16em;
}

.large {
  //width: 21.8182em;
  width: 22em;
}

.x-large {
  //width: 27.2727em;
  width: 27em;
}

.lastUnit {
  display: table-cell;
  *display: block;
  *zoom: 1;
  float: none;
  _position: relative;
  _left: -3px;
  _margin-right: -3px;
  width: auto;
}
