.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: 0;
  background: none;
  color: #000;
  font-weight: normal;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  text-decoration: underline;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  height: 16px;
  width: 16px;
  top: 7px;
}

.ui-datepicker .ui-datepicker-header {
  border: 0 !important;
}

.ui-autocomplete {
  max-height: 425px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
  /* add padding to account for vertical scrollbar */
  padding-right: 20px;
}

.ui-autocomplete-loading {
  background: white url('../img/progress-small.gif') right 5px no-repeat;
}

.ui-autocomplete-input {
  resize: none;
}

.ui-autocomplete {
  background: #fff;
}

.ui-autocomplete .ui-corner-all {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  border-radius: 0;
}

.ui-autocomplete .ui-state-hover {
  background: #bfee7d;
  border: 0;
  color: #000;
}

.ui-menu .ui-menu-item .ui-state-hover {
  margin: 0 !important;
}

.ui-autocomplete {
  padding: 0px !important;
}

.ui-widget-header .ui-icon {
  background-image: none;
}

.ui-datepicker .ui-datepicker-next-hover {
  background: transparent url('../img/icons/14/buttons/arrow_frw.png');
  border: 1px solid #EEE;
  border-color: #EEE #666 #666 #EEE;
  right: 2px;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev-hover {
  background: transparent url('../img/icons/14/buttons/arrow_bk.png');
  border: 1px solid #EEE;
  border-color: #EEE #666 #666 #EEE;
  left: 2px;
  cursor: pointer;
}

#ui-datepicker-div {
  z-index: 99999 !important;
}
