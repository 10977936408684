/*
	currency converter
*/
#currency-calc {
  margin: 0 20px;
  white-space: nowrap;
  display: block;
}

#currency-calc label {
  position: absolute;
  left: -9999em;
  top: -9999em;
}

#currency-calc select {
  display: block;
}

#currency-calc input {
  color: #7f7f7f;
  width: 60px;
}

#currency-calc input.last-used {
  color: #000;
}

#currency-calc > div {
  text-align: right;
  margin: 2px 20px 0;
  line-height: 1.6363em;
}

#currency-calc #currency-calc-to {
}

#currency-calc #currency-calc-from,
#currency-calc #currency-calc-to {
  float: left;
  width: 85px;
}

#currency-calc > div {
  float: left;
  width: 60px;
}

/*
		--- usergroup form ---
	*/
.privileges.tabbed {
  padding-left: 0;
  padding-right: 0;
}

.privileges > li {
  list-style: none;
}

.privileges > li > * {
  padding-left: 20px;
  padding-right: 20px;
}

.privileges > li > fieldset.general {
  display: block;
  width: 688px;
}

.privileges > li > fieldset {
  //float: left;
  display: inline-block;
  min-width: 10em;
  width: 186px;
  vertical-align:top;
  margin-bottom:14px;
}

.privileges > li > fieldset > legend {
  font-weight: bold;
}

/*
	PRJ: Projektseite
*/

#main.prj .main > ol {
  clear: both;
  list-style: none;
  margin: 0;
}

#main.prj .main > ol > li {
}

#main.prj.overview h1 {
  float: left;
}

#main.prj.overview h1 + ul {
  float: right;
}

#main.prj.overview #module-contents > ol {
  clear: both;
  list-style: none;
  padding: 0;
}

#main.prj.overview #module-contents > ol > li + li {
  margin-top: 1.4545em;
}

#main.prj.overview #module-contents > ol > li {
  background: transparent url(../img/tile-shadow-bottom.gif) repeat-x scroll left bottom;
  padding-bottom: 10px;
  border-bottom: 1px solid #1b1b1b;
}

#main.prj.overview #module-contents > ol > li > * {
  padding-bottom: 1px;

}

#main.prj.overview .overview {
  float: left;
  width: 33%;
  padding-bottom: 1.4545em;
}

#main.prj.overview li.letters .overview {
  padding-bottom: 0 !important;
}

.details > ul.toolbar,
.details > p {
  margin-bottom: 0;
}

.details > ul.toolbar-text {
  padding-bottom: 1.4545em !important;
}

#main.prj.overview .details {
  border-left: 1px solid #000;
  margin-left: 33%;
}

#main.prj.overview .details .list {
  border: 0px !important;
}

#main.prj.overview .overview > * {
}

#main.prj.overview ol > * {
  border-bottom: 1px solid #000;
  margin-bottom: 1.4545em;
}

#main.prj.overview .overview > .meter {
  padding: 0;
}

#main.prj.overview .overview > .meters {
  padding: 0;
  list-style: none;
  margin: 0;
}

#main.prj.overview .overview > .meters p {
  margin-left: 0;
}

#main.prj.overview .overview > .meters > li {
  border-top: 1px solid #888;
  padding: 1.4545em 20px 0;
}

#main.prj.overview .overview > .meters > li:first-child {
  border-top: 0;
  padding-top: 0;
}

#main.prj.overview .overview h2 {
  padding: 1px 20px;
  margin-left: 0;
  margin-right: 0;
  color: #fff;
  background: #1b1b1b;
  font-weight: normal;
  text-transform: uppercase;
}

#main.prj.overview .overview table {
  width: 100%;
  table-layout: fixed;
}

#main.prj.overview .overview tr > * {
  padding-right: 20px;
  vertical-align: middle;
}

#main.prj.overview .overview tr > *.number {
  text-align: right;
  padding-right: 40px;
}

#main.prj.overview .overview tr > :first-child {
  /* padding-left: 20px; */
}

#main.prj.overview .overview .amount {
  display: block;
  font-size: 2em;
  font-weight: bold;
}

#main.prj.overview .overview span.amount {
}

#main.prj.overview .details table {
  border-top: none; /* Fix for Gecko */
}

#main.prj.overview tr.disabled,
#dashboard .todos tr.disabled {
  text-decoration: line-through;
}

#main.prj.overview tr.disabled,
#dashboard .todos tr.disabled,
#main.prj.overview tr.not-accepted {
  color: #666;
}

table.proposal tr > * h2,
table.proposal tr > * p,
table.invoice tr > * h2,
table.invoice tr > * p,
table.invoice tr td ul {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0 !important;
  line-height: 1.5454em;
}

/* inform2.ui.initFilterables */
.filterable > * {
  display: block;
}

div.controls > * {
  display: block;
  margin: 5px 15px 0 0;
}

.filterable, div.controls {
  float: left;
  margin-right: 10px;
}

#message.widget {
  border-color: #ff0000 !important;
  border-bottom-width: 1px;
}

#message.widget > .hd {
  background-color: #ff6f00;
}

#message.widget > .bd > div {
  float: left;
}

#message.widget div.hd h1 {
  color: #fff;
}

#message.widget div.bd h1 {
  margin: 0 0;
  color: #ff6f00;
}

#message.widget .list {
  border: 0;
  box-shadow: 0 0 0 !important;
  -moz-box-shadow: 0 0 0 !important;
  -webkit-box-shadow: 0 0 0 !important;
}

#message.widget .bd {
  padding: 0 !important;
}

#message.widget img.icn-alert {
  margin: 10px auto;
  display: block;
}

#message .item .details {
  display: none;
}

#message .item .title {
  background: url(../img/icons/14/arrow_frw.png) no-repeat;
  text-indent: 15px;
  margin-left: 0;
  position: inherit;
  width: auto;
}

#message .item .title.collapse {

  background: url(../img/icons/14/arrow_down.png) no-repeat;
}

.main .number.loss {
  color: #FF0064;
}
