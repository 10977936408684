/*
	=== BASIC TYPOGRAPHY ======================================================
*/
body,
button,
input,
select,
textarea {
  font: 11px/1.4545 Arial, "Helvetica Neue", Helvetica, sans-serif;
}

body {
  background: #1b1b1b;
  color: #fff;
}

/* --- bottom margins for text elements --- */
address,
dl,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ol,
p,
pre,
table,
ul {
  margin-bottom: 0.4545em; /* = line-height of body */
}

/* --- horziontal margins for text elements --- */
address,
  /* dl, */
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul {
  margin-left: 20px;
  margin-right: 20px;
}

.text {
  display: block;
  width: 580px;
  float: left;
  margin-right: 40px;
}

.wiki-detail .text ul,
.wiki-detail .text ol,
.wiki-item .text ul,
.wiki-item .text ol {
  margin-left: 40px;
}

.article .expandable ul,
.article .expandable ol {
  margin-left: 40px;
}

.wiki-detail p .text ul,
.wiki-detail p .text ol {
  margin-left: 0px;
}

#dashboard .text {
  width: 480px;
  margin-bottom: 40px;
}

span.text {
  margin-left: 20px;
}

#dashboard span.text ul {
  margin-left: 0px;
}

/*
	=== LINKS =================================================================
*/

a {
  color: #12a3ac;
}

a:visited {
  color: #006C68;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: underline;
}

.head a,
.foot a,
.head .horiz,
.foot .horiz {
  color: #b1b1b1;
  text-transform: uppercase;
}

.head a:focus,
.head a:hover,
.head .current a,
.foot a:focus,
.foot a:hover,
.foot .current a {
  color: #fff;
  text-decoration: none;
}

/*
	=== LISTS =================================================================
*/
dl {
  display: inline;
  margin: 0;
  padding: 0;
}

dt {
  clear: left;
  font-weight: bold;
  float: left;
  margin-right: 1em;
}

ol {
  list-style: outside decimal;
}

ul {
  list-style: outside disc;
}

/*
	=== HEADINGS ==============================================================
*/
h1, .h1 {
  font-size: 1.6364em; /* 18px */
  font-weight: normal;
  line-height: 1.2222em; /* 22px */
  margin-bottom: 1.4545em; /* 16px */
  text-transform: none;
}

#dashboard .article h2.h1 {
  margin-bottom: 0.2em;
}

h1.adr {
  margin-bottom: 45px !important;
}

h2, .h2,
h3, .h3 {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
}

h2, .h2 {
  text-transform: uppercase;
}

h3, .h3 {
  margin-bottom: 0;
}

/* skiplink */
[rel="skip"] {
  background: #bfee7d !important;
  text-align: center !important;
  color: #000 !important;
}

/*
	=== TABLES ================================================================
*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
}

thead {
  background-color: #1b1b1b;
  color: #fff;
  text-transform: uppercase;
}

thead th {
  font-weight: normal;
}

tfoot > :first-child > * {
  border-top: 1px solid #e3e3e3;
}

tfoot ul.toolbar-text {
  margin-left: 0;
  padding: 10px 0 5px 0;
}

caption {
  text-align: left;
}

.widget caption {
  margin: 0 10px 0 20px;
}

tr > * {
  text-align: left;
  vertical-align: top;
}

.list tbody tr > td,
.list tbody tr > th {
  padding: 5px 0;
  text-align: left;
  vertical-align: top;
}

table table {
  margin: 0;
}

/*
	=== CLASSES ===============================================================
*/

.creation-info {
  color: #b1b1b1;
  font-size: 0.8182em;
  line-height: 1.4444em;
}

#note table.creation-info tr > td:last-child {
  max-width: 74%;
  min-width: 50%;
}

.overlay .creation-info {
  border-collapse: separate;
  border-top: 1px solid #DDDDDD;
  color: #CCCCCC;
  display: block;
  font-size: 0.8182em;
  line-height: 1.4444em;
  padding: 5px 0;
}

.comments {
  color: #b1b1b1;
  text-transform: none;
}

.comments tr > td[scope=row] {
  width: 440px;
  display: block;
}

.comments p {
  margin: 0;
  color: #000;
  font-size: 11px;
}

.comments.list {
  border-top: 2px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.comments.list.wiki-detail .bd > .text,
.comments.list.wiki-detail .bd > ul {
  padding: 20px !important;
}

.comments.list.wiki-detail .item:hover {
  background-color: #fff;
}

/*
	=== PIPE ===============================================================
	@param <span class="pipe">|</span>
	@desc a pipe with a fix margin-(left/right)
*/
span.pipe {
  margin: 0 10px;
}

table.list tr.h1 > * {
  padding-top: 10px;
  padding-bottom: 10px;
}

/*
	=== INFOTEXT ===============================================================
	@desc simple infotext
*/

.info {
  color: #999999;
}

.err {
  color: #F00;
}

.suc {
  color: #00BB00;
}

/*
	=== HIDE ===============================================================
	@desc hides the element / confirm-msg
*/
.hide {
  display: none;
}

ul.toolbar-box.item {
  border-top: 0 !important;
}

.relative {
  position: relative;
}
