/* ====== Columns ====== */
.leftCol {
  float: left;
  width: 260px;
}

.rightCol {
  float: right;
  width: 240px;
}
