/*
	=== C O N T E N T   O B J E C T S =========================================
*/

/*
	Represents an entity (e.g. a db object)
*/
.item {
  position: relative;
}

/*
	A list of items
*/
.list {
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  border-collapse: separate; /* borders vanish when apllying background with collapse */
  list-style: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0 3px 10px #c6c6c6;
  -moz-box-shadow: 0 3px 10px #c6c6c6;
  -webkit-box-shadow: 0 3px 10px #c6c6c6;
}

#dashboard .list {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

form.list-form .list {
  border-width: 2px !important;
  margin-bottom: 0;
  -moz-box-shadow: none !important;
}

.list:last-child {
  margin-bottom: -1px;
}

table.list {
  width: 100%;
  margin-bottom: 10px;
}

.bd table.list {
  -moz-box-shadow: none;
  margin-bottom: 0 !important;
  width: 100%;
}

.equalheight-container .bd table.list {
  -moz-box-shadow: none;
  margin-bottom: 0 !important;
  width: 100%;
  margin-top: -20px;
}

.bd #prj-services table.list {
  margin-top: 0;
}

.details table.list {
  width: 100%;
  margin-bottom: 1.4545em !important;
}

table.list tr > :first-child,
table.list .caption {
  padding-left: 20px;
}

table.metabox tr > :first-child {
  padding-right: 20px;
}

div.metabox > table > tr > :first-child {
  padding-right: 20px;
}

div.metabox {
  border-bottom: 2px solid #000;
  /* border-top: 2px solid #000; */
  border-collapse: separate; /* borders vanish when apllying background with collapse */
  list-style: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0 3px 10px #c6c6c6;
  -moz-box-shadow: 0 3px 10px #c6c6c6;

  -webkit-box-shadow: 0 3px 10px #c6c6c6;
}

div.metabox .list {
  border-bottom: none;
  /* border-top: 2px solid #000; */
  box-shadow: none;
  -moz-box-shadow: none;

  -webkit-box-shadow: none;
}

div.metabox > table {
  float: left;
  width: 50%;
}

.outstanding {
  color: #ff0064;
}

div.metabox span.finished {
  color: #999999;
  text-decoration: line-through;
}

div.metabox > table > tbody.borderright {
  border-right: 1px solid #999999;
}

table.list tr td.item-title table {
  margin-left: 0 !important;
}

table.list tr td .hidden-item-title {
  display: none;
}

table.list tr td table td {
  padding-left: 0 !important;
}

.item-title .orig-title,
.item-title .sub-title {
  width: 270px;
  display: block;
  line-height: 12px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.item,
table.list tbody.item tr:first-child > *,
table.list tr.item > * {
  border-top: 1px solid #e3e3e3;
}

table.list tr.item > .fadenumber, table.list tr.item > .offered, table.list tr.item > .billed,
table.list tr.item > .finished, table.list tr.item > .declined {
  color: #999999;
}

table.list tr.item > .finished, table.list tr.item > .declined {
  text-decoration: line-through;
}

table tr.finished, table tr.declined {
  color: #999999;
  text-decoration: line-through;
}

table.list tr.item > .tobill {
  color: #FF0064;
}

.list tbody tr td.vert_division,
.list thead tr th.vert_division,
.list tfoot tr td.vert_division {
  border-left: 1px solid #999999;
  padding-left: 5px;
}

.list tr.horiz_division td {
  border-top: 1px solid black;
}

tbody.item tr > *.list-hide {
  padding-top: 0;
  padding-bottom: 0;
}

tbody.item:hover tr > *.list-hide {
  border-top: 1px solid #e3e3e3 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

tr.subheading > *,
tbody.item:hover tr:first-child > * {
  border-top: 1px solid #000 !important;
}

tbody.item tr:first-child > * {
  padding-top: 5px;
}

.list .item:hover {
  background-color: #f8f8f8;
}

.list .item:first-child,
.list .item:first-child td,
.list-adr .item,
.list-adr-single .item,
.list-adr-slidable .item,
.slidable .item,
.bd .list {
  border-top: none;
}

.list-adr, .list-adr-single {
  list-style: none;
}

.list-adr-single {
  min-height: 120px;
}

table.list-adr tr > * {
  padding-top: 5px;
  padding-bottom: 5px;
}

table.list-adr tr > * table.com tr > * {
  padding-top: 0;
  padding-bottom: 0;
}

.list-adr .postal p {
  margin-bottom: 1.4545em;
}

.list-adr table.com {
  margin-bottom: 0;
}

.list-adr table.creation-info {
  margin-left: 0;
}

.list-adr table.creation-info tr:first-child > *,
.creation-info .item:hover tr:first-child > * {
  border-top: 0 !important;
}

.list-adr-single .postal p,
.list-adr-single table.com {
  margin-left: 0px !important;
}

.postal p a.googleMapsLink:link,
.postal p a.googleMapsLink:active,
.postal p a.googleMapsLink:visited {
  margin-top: 5px;
  text-decoration: none;
  color: #12a3ac;
}

.postal p span.googleMapsLinkContainer {
  display: block;
  padding-top: 5px;
}

.postal p a.googleMapsLink:hover {
  color: #12a3ac;
  text-decoration: none;
}

.postal p a.googleMapsLink:hover span.underlined {
  text-decoration: underline;
}

table.membership-data-table th {
  padding-right: 5px;
}

/* abstand zwischen label und value vergroessert */
.list-adr-single table.com tbody tr > th,
.list-adr table.com tbody tr > th {
  width: 55px !important;
}

.list-adr-single ul.toolbar-item {
  margin-right: 0px !important;
}

.list-adr table.com {
  margin-left: 0px !important;
}

.list-adr .vcard table.com {
  margin-left: 20px !important;
  margin-top: 0 !important;
}

/*
	skype und icq icon
*/

.com img.iconCommunication {
  width: 11px;
  height: 11px;
  margin-right: 5px;
}

/*
	subnavi des Cals
	@extends form, .list
*/
.list-cal {
  color: #000;
  display: block;
}

.list-cal h4 {
  margin-bottom: 0px;
  color: #999;
  font-weight: bold;
}

.list-cal .item {
  border-top: 1px solid #f8f8f8;
}

.list-cal .item:first-child {
  border-top: none;
  margin-top: 5px;
}

.list-cal .item ul li {
  margin-right: 5px;
}

ul.mycalendars li.item {
  background: transparent url(../img/tiles-y.png) repeat-y right 0;
}

/* prj/list */
.list tr.item.mainproject > * {
  border-top: 1px solid #000;
  font-weight: bold;
}

/* timesheet */
table.timesheet tr.item > td {
  border-left: 1px solid #E3E3E3;
  text-align: center;
}

table.timesheet tr.item > td img {
  margin-top: 2px;
}

table.timesheet thead th {
  text-align: center;
}

table.timesheet thead th.re-today {
  background-color: #12A3AC;
}

table.timesheet thead th.re-today a {
  color: #000;
}

table.timesheet tr.item > td:first-child {
  text-align: left !important;
  border-left: 0;
}

table.timesheet tr.item > td > div {
  position: relative;
  height: 100%;
  padding-top: 45px;
}

table.timesheet tr.item > td a.new-event-button {
  display: none;
  position: absolute;
  top: 0;
  left: 1px;
}

table.timesheet tr.item > td:hover a.new-event-button {
  display: inline;
}

table.timesheet tfoot tr.item:first-child > *,
tr.total > * {
  border-top: 1px solid #000000;
  font-weight: bold;
}

.proposal tfoot tr.item:first-child > *,
.invoice tfoot tr.item:first-child > * {
  border-top: 2px solid #000000 !important;
}

.list tr.subtotal:not(.itemgroup) > * {
  border-top: 1px solid #000000 !important;
  font-weight: bold;
}

*.number:not(:first-child) {
  text-align: right;
  padding-right: 15px;
}

.number {
  white-space: nowrap;
}

.prj *.number {
  text-align: left !important;
}

.list tr.item > *.weekend,
.list.resources div.weekend {
  background-color: #d6d6d6;
}

.list thead tr > *.today {
  border-left: 1px solid #3CCFC3;
  border-right: 1px solid #3CCFC3;
}

.list tfoot tr:first-child td {
  border-top: 2px solid #000000;
}

.timesheet thead tr td:first-child,
.timesheet tr.item th:first-child {
  padding-right: 1.4545em;
}

/*
	List of thumbs
*/
ul.thumbs, ul.thumb-links {
  padding: 0;
  vertical-align: bottom;
  position: relative;
  text-align: center;
  width: 100%;
  margin-left: 0;
}

ul.thumbs li.item {
  border: 1px solid #fff;
  float: left;
  padding: 5px;
  text-align: center;
  width: 177px;
  min-height: 270px;
  height: 270px;
  list-style: none;
  margin-right: 10px;
  overflow: hidden;
}

ul.thumbs li.item a.item-title {
  display: block;
  height: 12px;
  margin-bottom: 3px;
}

ul.thumbs li.item:hover {
  background-color: #fff;
}

ul.thumbs li.item div.tile {
  background-color: #f8f8f8;
  padding: 5px 5px 5px 5px;
  text-align: center;
  height: 165px;
}

html.scripted .thumb-links {
  left: -9999em;
  position: relative !important;
  top: -9999em;
  margin-left: 0px;
}

/*
	A list of links
*/
.links,
.links ul {
  list-style: none;
}

.links a {
  text-decoration: none;
}

/*
	List of links, horizontal, seperated by pipes.
	@extends .links
*/
.horiz {
  float: left;
}

.horiz li {
  float: left;
  margin-top: 2px;
}

.horiz li:before {
  content: "|";
}

.horiz li:first-child:before {
  content: "";
}

.horiz a {
  padding: 0 10px;
}

.horiz li:first-child a {
  padding-left: 0;
}

/*
	Tab links, no separator
	@extends .horiz
*/
.tabs {
  border-bottom: 1px solid #000;
  float: none;
  line-height: 20px;
  margin-right: 0;
  margin-left: 0;
  padding-left: 0px; /*	padding-left: 20px; - bei 1024px rutschten die tabs in 2 spalten*/
  overflow: hidden;
}

.tabs > * {
  border: 1px solid #999;
  border-width: 1px 1px 0 0;
  background-color: #f8f8f8;
}

.tabs > li:before {
  content: "";
}

.tabs > :first-child {
  border-left-width: 1px;
}

.tabs .current {
  border: none;
}

.tabs a {
  color: #000;
  display: block;
  padding: 0 20px;
}

.tabs li:first-child a {
  padding-left: 20px;
}

.tabs .current a {
  background-color: #000;
  color: #fff;
  margin-top: -2px;
  padding-bottom: 3px;
  border: none;
}

.tabs .busyIndicating {
  border: none;
  background: transparent;
  width: 28px;
}

/*
	Toolbar, links are displayed as icons.
	@extends .links
*/
.toolbar:after {
  clear: both;
  content: "\00a0";
  display: block;
  height: 0;
  visibility: hidden;
}

.toolbar {
  _zoom: 1;
  margin-right: 10px;
}

.picked-contacts > ol > li {
  overflow: hidden;
  height: 20px;
  line-height: 20px;
}

.picked-contacts > ol > li > a,
.picked-contacts > ol > li > span,
.picked-contacts > ol > li > .toolbar {
  float: left;
}

.picked-contacts > ol > li > .toolbar {
  display: none;
  margin-bottom: 0;
}

.picked-contacts > ol > li > .toolbar.show {
  display: block;
}

.picked-contacts > ol > li > a > img {
  padding-top: 3px; /* for the li-Elements height is 20px and the icons height is 14px */
}

.toolbar li {
  float: left;
  position: relative;
  list-style: none outside none;
}

.toolbar a {
  display: block;
  height: 16px;
  margin: 1px 5px 1px 0;
  overflow: hidden;
  text-indent: 50px;
  white-space: nowrap;
  width: 16px;
}

.toolbar img.busyIndicator {
  vertical-align: middle;
  /* default */
  display: none;
}

#tree .toolbar a {
  margin: 0;
}

.toolbar li:first-child a {
  margin-left: 0;
}

.toolbar li ul {
  background: #e3e3e3;
  border: 1px solid #000;
  box-shadow: 0 3px 10px #1b1b1b;
  -moz-box-shadow: 0 3px 10px #1b1b1b;
  -webkit-box-shadow: 0 3px 10px #1b1b1b;
  display: none;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: right;
  z-index: 500;
  line-height: 1.7em !important;
}

.toolbar li:hover ul,
.toolbar li.focus ul {
  display: block;
}

.toolbar li li {
  float: none;
}

.toolbar li li a {
  background: none;
  border: none;
  display: block;
  height: auto;
  margin: 0;
  padding: 0 5px;
  text-indent: 0;
  width: auto;
}

.toolbar li li a[rel] {
  background: none;
  margin-top: 0px;
}

.toolbar li li a:focus,
.toolbar li li a:hover {
  background-color: #b1b1b1;
}

/*
	Haupt-Toolbar, fliesst nach rechts.
	@extends .toolbar
*/
.toolbar-main {
  float: right;
  margin-right: 20px;
}

.toolbar-main a {
  border: none;
  height: 28px;
  margin: 4px 0 0 10px;
  width: 28px;

  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

/*
	Haupt-Toolbar in der Sidebar, fliesst nach links.
	@extends .toolbar-main
*/
.toolbar-sidebar {
  float: left;
}

/*
	Toolbar for a .box.
	@extends .toolbar
*/
.toolbar-box {
  margin-top: 0.3636em;
  position: absolute;
  right: 0;
  top: -0.3636em;
}

/*
	Toolbar for an .item (usually a db row)
	Only visible when .item is hovered or focused.

	MUST ONLY BE USED INSIDE A CONTAINER WITH CLASS "item"!

	@extends .toolbar
*/
html.scripted .toolbar-item {
  position: absolute;
  left: -9999em;
  top: -9999em;
}

.item:hover .toolbar-item,
.item.focus .toolbar-item {
  left: auto;
  right: 0;
  top: 0;
}

/*
	tr can't have position: relative in some browsers
	special treatment ...
*/
html.scripted table.list .item .toolbar-item {
  margin-bottom: 0;
  position: static;
  visibility: hidden;
}

html.scripted table.list .item.focus .toolbar-item,
html.scripted table.list .item:hover .toolbar-item {
  visibility: visible;
}

/*
	Toolbar for an .item (usually a db row)
	Only visible when .item is hovered or focused.

	MUST ONLY BE USED INSIDE A CONTAINER WITH CLASS "item"!

	@extends .toolbar
*/
html.scripted .toolbar-tree {
  position: absolute;
}

.item:hover .toolbar-tree,
.item.focus .toolbar-tree {
  left: auto;
  right: 0;
  top: 0;
  visibility: visible;
}

/*
	tr can't have position: relative in some browsers
	special treatment ...
*/
html.scripted .toolbar-tree {
  margin-bottom: 0;
  visibility: hidden;
}

.toolbar-tree a[rel] {
  margin: 3px 0;
  padding: 0 !important;
  border: none;
  background-color: transparent;
  float: right;
}

.toolbar-tree a[rel]:hover {
  background-color: transparent;
  padding: 0 !important;
  margin: 3px 0;
}

/*
	Toolbar mit sichtbarem text

	@extends .toolbar
*/
.toolbar-text a {
  margin: 5px 5px 5px 0;
  padding: 0 10px;
  text-indent: 0;
  width: auto;
}

.toolbar-text a[rel] {
  margin-right: 5px;
  padding-left: 17px; /* Icon */
}

#td-flag-status-select {
  background: #1b1b1b url(../img/tiles-x.png) repeat-x 0 0;
  color: #fff;
  margin: 0 0 0 -16px;
  text-transform: uppercase;
  border-color: rgb(27, 27, 27);
}

/*
	Basisklasse fuer Navigationen
	@extends .links
*/
.nav {
}

.nav h2,
.nav .h2,
.nav [rel="skip"] {
  position: absolute;
  left: -9999em;
  top: -9999em;
}

.nav [rel="skip"]:focus {
  left: 0;
  top: auto;
  right: 0;
}

.nav ul {
  margin-left: 0;
  margin-right: 0;
}

.nav a {
  color: #000;
  display: block;
  padding: 0 20px;
  text-decoration: none;
}

/*
	Hauptnavigation
	@extends .nav
*/
.nav-main {
  border-top: 9px solid #63eee3;
  text-transform: uppercase;
}

.nav-main > ul {
  background: url(../img/nav-separators.gif) no-repeat right -141px;
  float: left;
  margin: -9px 0 0;
  padding-right: 1px;
}

.nav-main li {
  border-top: 9px solid #bfee7d;
  float: left;
  width: 120px;
}

.nav-main a {
  background: url(../img/nav-separators.gif) no-repeat left top;
  color: #b1b1b1;
  margin-top: -9px;
  padding: 18px 10px 9px;
}

.nav-main a:focus,
.nav-main a:hover,
.nav-main .current > a {
  color: #fff;
}

.nav-main .dashboard {
  border-color: #b2ee8c;
}

.nav-main .adr {
  border-color: #a6ee99;
}

.nav-main .cal {
  border-color: #98eea8;
}

.nav-main .prj {
  border-color: #8aeeb8;
}

.nav-main .lib {
  border-color: #6feed5;
}

/*
	Sidebar-Navigation
	@extends .nav
*/
.nav-sidebar {
  background: #cacaca url(../img/tiles-y.png) repeat-y right 0;
  padding: 0;
}

.nav-sidebar h2 + ul
.nav-sidebar ul:first-child {
  border-top: none;
}

.nav-sidebar > ul {
  border-color: #e4e4e4 #cacaca #b1b1b1;
  border-style: solid;
  border-width: 1px 0;
  margin: 0;
  padding: 1.4545em 0;
}

.nav-sidebar a {
  padding-bottom: 2px;
  padding-top: 2px;
}

.nav-sidebar ul a:focus,
.nav-sidebar ul a:hover,
.nav-sidebar ul .current > a {
  background: #e3e3e3 url(../img/tiles-y.png) repeat-y right 0;
}

.nav-sidebar > ul#toggle_buttons a:hover,
.nav-sidebar > ul#toggle_buttons a:active,
.nav-sidebar > ul#toggle_buttons a:focus {
  background: none;
}

/*
	Sidebar-Treenavigation
	@extends .nav-sidebar
*/

#tree, .tree {
  background: #f8f8f8 url(../img/tiles-y.png) repeat-y right 0;
  padding: 10px 0;
}

.tree h2 + ul
.tree ul:first-child {
  border-top: none;
}

.tree > ul {
  margin: 0;
  padding: 1.4545em 0;
}

.tree a {
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 30px;
}

/*
	Eine Terminliste
*/
.agenda {
  list-style: none;
}

/*
	Ratings
*/
.rating {
  overflow: hidden;
}

.rating strong {
  position: absolute;
  left: -9999em;
  top: -9999em;
}

.rating * {
  float: left;
}

.rating span,
.rating var {
  background: url(../img/icons/16/icn_rating_off.png) repeat-x left top;
  overflow: hidden;
  width: 0;
}

.rating var {
  background-image: url(../img/icons/16/icn_rating_on.png);
  text-indent: -1000em;
}

.rating .rate-1 {
  padding-right: 20px;
}

.rating .rate-2 {
  padding-right: 40px;
}

.rating .rate-3 {
  padding-right: 60px;
}

.rating .rate-4 {
  padding-right: 80px;
}

.rating .rate-5 {
  padding-right: 100px;
}

/*
	=== UI-ELEMENTE ===========================================================
*/
/* Ein Overlay ueber der Seite */
.overlay {
  background: url(../img/overlay-background.png);
  padding: 1.4545em 20px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.overlay > .loading {
  background: url(../img/progress.gif) center top no-repeat;
  left: 0;
  margin-top: -10px;
  padding-top: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}

.overlay > .loading a {
  color: #eee;
}

.overlay > .loading a:focus,
.overlay > .loading a:hover {
  color: #fff;
}

.errors {
  list-style: none;
  color: #ff0064;
  padding-left: 0;
}

.errors li,
.has-errors {
  padding-left: 20px;
  background: url(../img/icons/14/icn_warning.png) left 2px no-repeat !important;
}

li.current .has-errors {
  background-color: #000 !important;
}

.has-errors {
  background-position: 4px center;
}

/*
	Kindknoten werden als Tabs ansteuerbar

	@scripted
*/
.tabbed {
}

.scripted .tabbed > * {
  display: none;
}

.scripted .tabbed > * > h2 {
  display: none;
}

.scripted .tabbed > :first-child,
.scripted .tabbed > .tabs + *,
.scripted .tabbed > .tabs {
  display: block;
  width: 768px;
}

/*
	Die Tabnavigation wird ueber dem Container dargestellt, ohne dass der
	Container nach unten rutscht.

	@extends .tabbed
*/
.tabbed-boxes {
}

.tabbed-boxes > .tabs {
  border-bottom: none;
  margin-bottom: 0;
  margin-top: -23px;
}

/*
	Horizontal verschiebbare Boxen
*/
.slidable {
  min-height: 15em;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.scripted .slidable {
  min-height: 22.7273em;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}

.scripted .slidable > :first-child {
  list-style: none;
  margin: 0;
  width: 10000%;
}

.scripted .slidable > :first-child > li {
  float: left;
  position: relative;
  width: 1%;
}

.slidable .slidable-index {
  line-height: 20px;
  padding: 0 5px;
}

/*
	Toolbar fuer Slidables
	@extends .toolbar
*/
ul.toolbar-slidable {
  bottom: 0px;
  position: absolute;
  text-align: right;
  height: 22px;
  float: right;
  right: 0;
}

/*
	--- Meters ---
*/
.meter {
  background: #eee;
  height: 1em;
}

.list-view .meter,
.metabox .meter {
  margin-top: 3px;
  margin-left: 0;
}

.meter > * {
  background: #b6ec6e;
  float: left;
  height: 0;
  overflow: hidden;
  padding: 1em 0 0;
}

.meter > .exceeded {
  background: #fa0e56;
}

.meter.inverted > * {
  background: #fa0e56;
}

.meter.inverted > .exceeded {
  background: #b6ec6e;
}

.meter > .near-limit {
  background: #46ebe7;
}

.meter.project {
  background: #fa0e56;
}

.meter.project > .near-limit,
.meter.project > .exceeded {
  background: #46ebe7;
}

.meter.invoice {
  background: #fa0e56;
}

.meter.invoice > .near-limit,
.meter.invoice > .exceeded {
  background: #b6ec6e;
}

.meter.forecasts {
  background-color: #46EBE7;
}

.meter.forecasts > .overdue {
  background: #FA0E56;
}

/*
	=== M I X I N S ===========================================================
*/
/* Floats right */
.aux {
  float: right;
}

/* Makes an element collapsible */
html.scripted .expandable.collapsed {
  height: 4.7272em;
  overflow: hidden;
}

.expandable a[rel="expand"] {
  bottom: 0.7273em;
  position: absolute;
  right: 20px;
}

/*
	=== JS-GENERATED ELEMENTS =================================================
*/
.state {
  background: left center no-repeat;
  padding-left: 15px;
}

.state.closed {
  background-image: url(../img/icons/14/arrow_frw.png);
}

.state.opened {
  background-image: url(../img/icons/14/arrow_down.png);
}

/*
	beinhaltet zu bearbeitende Snippets ausserhalb des WYSIWYG-Editors (+ edit-btn)
	@extends .mod
*/

.hewbox {
  display: block;
  border: 0;
  margin: 0 0 1.4545em 20px !important;
  background: #EEEEEE none repeat scroll 0 0;
  padding: 5px 85px 10px 10px;
  line-height: 1.4em;
  min-height: 20px;
  width: 550px;
  overflow: hidden;
}

/*
 * overflow:hidden (above) does not hide the part beneath the padding (85px). So an after-element is placed upon.
 */
.hewbox:after {
  content: '';
  width: 85px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjI1JSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(left, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 25%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(238, 238, 238, 0)), color-stop(25%, rgba(238, 238, 238, 1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 25%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 25%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 25%); /* IE10+ */
  background: linear-gradient(to right, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 25%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00eeeeee', endColorstr='#eeeeee', GradientType=1); /* IE6-8 */
}

.hewbox * {
  overflow: visible;
}

.hewbox h2 {
  margin: 0;
  padding: 0;
}

.hewbox p {
  margin: 10px 0;
  padding: 0;
}

.hewbox ul.toolbar {
  position: absolute;
  right: 10px;
  top: 0;
  margin: 0;
  bottom: 0;
  z-index: 1;
}

.hewbox a[rel="expand"] {
  padding: 0 5px;
  right: 10px;
  z-index: 1;
}

.hewbox ul,
.hewbox ol {
  list-style: inside disc;
  padding-left: 1px;
  margin-left: 0px;
}

.detail-closed .footer .hewbox {
  margin-top: 1.4545em !important;
}

/*
	beinhaltet metainfos / prj
	@extends .list
*/

table.metabox {
  float: left;
  width: 300px;
  border-bottom: 0px;
  margin-left: 20px;
  margin-bottom: 20px;
}

div.metabox {
  float: left;
  width: 600px;
  border-bottom: 0px;
  margin-left: 20px;
  margin-bottom: 20px;
}

div.metabox p,
table.metabox p {
  margin: 0;
}

/*
*
* 	.wiki-item
*	wiki-item aus liste
*	@extends .wiki
*
*/

.wiki-item {
  margin-bottom: 0px;
  padding: 1.4545em 0;
}

.wiki-item .fd {
  border-collapse: separate;
  border-top: 1px solid #e3e3e3;
  color: #e3e3e3;
  display: block;
  font-size: 0.8182em;
  margin: 10px 0 -10px 0;
}

.wiki-item .bd img,
table.thumbs tr.item img {
  padding: 5px;
  background-color: #e3e3e3;
}

.wiki-item div.tags {
  max-width: 300px;
}

/*
*
* 	.wiki-detail
*	wiki article-view
*	@extends .wiki
*
*/

.wiki-detail {
  margin-bottom: 0px;
  padding: 0;
  display: block;
}

.wiki-detail .hd {
  margin: 0 20px;
}

.wiki-detail .breadcrumb > * {
  color: #999;
  font-weight: bold;
}

.wiki-detail .bd .files,
.expense-detail .bd .files {
  margin-left: 20px;
  width: 580px;
  height: 90px;
}

.wiki-detail .bd .files span,
.expense-detail .bd .files span {
  float: left;
  vertical-align: top;
  padding: 5px;
  background-color: #e3e3e3;
  margin: 10px;
  text-align: center;
}

.wiki-detail .bd .files span a span,
.expense-detail .bd .files span a span {
  height: inherit;
  padding: 0 !important;
  margin: 0 !important;
}

.wiki-detail .fd {
  border-top: 1px solid #e3e3e3;
  color: #e3e3e3;
  display: block;
  font-size: 0.8182em;
  margin: 10px 0 30px 0;
  padding: 10px 0;
}

/*
*
* 	.wiki-info
*	wiki article-view
*	@extends .wiki
*
*/
.wiki-info {
  display: inline;
  width: 175px;
  padding-left: 25px;
  color: #999;
}

.wiki-info h3 {
  margin-left: 0;
  margin-bottom: 0;
}

.wiki-info span.tags a {
  display: block;
}

table.creation-info tr > * {
  padding: 1px 5px 0 0 !important;

}

/*
*
* 	.item-img
*	img zum .item-title
*	@extends .adr .item-title
*
*/

.item-img {
  height: 70px;
  width: 70px;
}

.item-img a[rel=download] span {
  height: 70px;
  width: 70px;
  background-color: #fff !important;
  display: block;
}

/*
*
* 	.item-img-boy/-girl/-office
*	platzhalter fuer info-img (adr/view)
*	@extends .adr .item-img
*
*/
.item-img-m {
  background: url(../img/dummies/icn_boy.png) no-repeat;
}

.item-img-f {
  background: url(../img/dummies/icn_girl.png) no-repeat;
}

.item-img-office {
  background: url(../img/dummies/icn_office.png) no-repeat;
}

#contactpicker_selected #maxContacts {
  position: absolute;
  text-indent: -9999px;
}

/*
	=== PRJ#tree sub-sub-navi icons ======================================================
*/

.all_prjs a,
.all_ts a,
.all_ma a,
.all_prj a,
.all_bill a,
.all_prop a,
.all_inv a,
.all_exp a {
  padding: 2px 40px !important;
}

.all_ma a {
  margin-bottom: 15px;
}

.prj-unit-form form {
  padding: 10px 0 0 20px;
}

.prj-unit-form ul > span,
.prj-unit-form ul > *:focus,
.prj-unit-form ul > *:hover,
.prj-unit-form ul > .current {
  background: #e3e3e3 url(../img/tiles-y.png) repeat-y scroll right 0;
}

/*
	=== tinyMCE editor ======================================================
*/

span.mceEditor {
  margin-bottom: 0.45em;
  display: block;
}

span.mceEditor table.mceToolbar {
  width: 480px;
}

li.wide span.mceEditor table.mceToolbar,
#snippetsForm span.mceEditor table.mceToolbar {
  width: 650px;
}

li.wide span.mceEditor table.mceToolbar tr td,
#snippetsForm li span.mceEditor table.mceToolbar tr td {
  width: 5%;
}

li.wide span.mceEditor table.mceToolbar tr td.mceFirst,
#snippetsForm li span.mceEditor table.mceToolbar tr td.mceFirst {
  width: 0.1%;
}

li.wide span.mceEditor table.mceToolbar tr td.mceLast,
#snippetsForm li span.mceEditor table.mceToolbar tr td.mceLast {
  width: 64%;
}

#snippetsForm li {
  padding-left: 0px;
}

ul.snippets ul.editing {
  position: absolute;
  left: -9999em;
  top: 5px !important;
  right: auto;
}

ul.snippets > li:hover ul.editing,
ul.snippets > li.focus ul.editing {
  right: 5px;
  left: auto;
}

/*
	=== BIB TREE ======================================================
*/
#tree ul.closed {
  display: none;
}

#tree a.pathLink {
  margin-left: 0;
  padding: 0px 0px 0px 0px;
}

#tree li a.pathLink {
  padding: 0 0 0 0;
}

#tree li li a.pathLink {
  padding: 0 0 0 15px;
}

#tree li li li a.pathLink {
  padding: 0 0 0 30px;
}

#tree li li li li a.pathLink {
  padding: 0 0 0 45px;
}

#tree li li li li li a.pathLink {
  padding: 0 0 0 60px;
}

#tree li li li li li li a.pathLink {
  padding: 0 0 0 75px;
}

#tree .pathLink:hover,
#tree a.current,
#tree .droppable_file_hover {
  background: #e3e3e3 url(../img/tile-module-nav-shadow.png) repeat-y scroll right center;
  text-decoration: none;
}

em.file {
  display: none;
}

html.scripted #tree li > .editing {
  position: absolute;
}

html.scripted #tree a:hover + .editing,
html.scripted #tree a.focus + .editing,
html.scripted #tree .editing:hover,
html.scripted #tree .editing.focus {
  position: static;
}

#tree .editing {
  margin-top: -1.4545em;
}

#tree .editing a[rel] {
  padding: 0 0 0 12px !important;
  border: none;
  background-color: transparent;
}

#tree .ab {
  right: 10px;
  z-index: 89;
  background: transparent;
  float: right;
}

#tree .ab a {
  float: right;
}

#tree .ab a strong {
  visibility: hidden;
}

/*
	=== MDB ======================================================
*/

table.mdb {
  margin-bottom: 0;
}

table.mdb tbody tr td {
  padding: 1em 0;
}

.thumbs {
  display: table;
  vertical-align: bottom;
}

.thumbs .icon {
  border: none;
}

.media_description {
  margin: 0 0 0 100px;
}

ul.thumbs li {
  position: relative;
}

ul.thumbs img:first-child {
  vertical-align: bottom;
}

ul.thumbs > li:first-line {
  display: block;
  line-height: 167px;
  height: 167px;
}

ul.thumbs div {
  margin: 0.4545em 0 1.4545em;
  padding-left: 20px;
  text-align: left;
}

ul.thumbs input.batchedit {
  position: absolute;
  margin-left: -20px;
}

span.fileinfo {
  display: block;
}

ul.thumbs ul.editing {
  position: relative;
}

ul.thumbs li:hover ul.editing,
ul.thumbs li.focus ul.editing {
  position: static;
}

.expand,
.collapse {
  background: url(../img/icons/14/arrow_frw.png) no-repeat center;
  overflow: hidden;
  margin-left: -17px;
  position: absolute;
  text-indent: 20px;
  width: 14px;
}

.collapse {
  background-image: url(../img/icons/14/arrow_down.png);
}

.masterCollapse.expand {
  background: url(../img/icons/14/arrow_frw_white.png) no-repeat center;
}

.masterCollapse.collapse {
  background: url(../img/icons/14/arrow_down_white.png) no-repeat center;
}

/*
=== RESETTED LISTS =====================================================
*/
ul.editing {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

ul.editing li {
  float: left;
  padding: 0 !important;
}

.editing a[rel] {
  color: #000;
  height: 16px;
  padding: 0 0 0 15px;
  overflow: hidden;
  text-indent: 1px; /* for safari */
  width: 1px; /* for safari */
}

.editing a[rel],
.editing .button-group-holder,
button.rel {
  margin-left: 2px;
}

.editing a[rel],
.editing .button-group-holder {
  float: left;
}

.editing a[rel]:hover {
  text-decoration: none;
}

.editing .button-group-holder > a[rel] {
  margin-left: 0;
}

/*
    --- special editing buttons ---

    EDITING POSITIONS
*/

html.scripted td.editing > *,
html.scripted .tree li > .editing {
  position: relative;
  left: -1000em;
  top: -1000em;
  margin: 0 5px 0 0;
}

html.scripted tr:hover td.editing > *,
html.scripted tr.focus td.editing > * {
  position: static;
  left: 0;
  top: 0;
}

#module-subnav p.editing {
  float: right;
  margin: -1.5545em 0 0;
  position: relative;
  top: -1000em;
  left: -1000em;
}

#module-subnav :hover > p.editing,
#module-subnav .focus > p.editing {
  position: static;
}

#main.prj.overview p.editing {
  background: transparent;
  border-bottom: none;
  padding-left: 20px;
}

/**
 * INT-RG Projects
 */
#main span.icon-internal {
  width: 48px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-image: url(/img/icons/icn-intern.png);
  background-size: 48px 16px;
}

#main span.icon-internal-small {
  width: 35px;
  height: 11px;
  display: inline-block;
  vertical-align: middle;
  background-image: url(/img/icons/icn-intern-small.png);
  background-size: 35px 11px;
}

#main tbody td.item-title span.icon-internal-small,
#main tbody tr.item span.icon-internal-small {
  width: 35px;
  height: 11px;
  display: inline-block;
  background-image: url(/img/icons/icn-intern-small.png);
  background-size: 35px 11px;
  float: right;
  margin-right: 8px;
  margin-top: 2px;
}

/**
 * project settings
 **/

.project-settings {
  position: relative;
  padding: 1.4545em 0 1.4545em;
  overflow: hidden;
}

.project-settings-last {
  border-bottom: 1px solid #E3E3E3;
  margin-bottom: 15px;
}

.project-settings ul.toolbar {
  position: absolute;
  left: 450px;
  top: 10px;
}

.project-settings table {
}

.project-settings table tbody th {
  width: 280px;
}

.project-settings-headline {
  padding: 1.4545em 0 0 !important;
}

.project-settings-headline h1 {
  margin-bottom: 0.4545em;
}

.project-settings-headline ul.toolbar {
  left: auto !important;
  top: auto !important;
  right: 2px;
  bottom: 0;
}

/**
 * vertical tabs
 **/
.vertical {
  overflow: hidden;
  border-bottom: 2px solid black;
  box-shadow: 0 3px 10px #c6c6c6;
  -moz-box-shadow: 0 3px 10px #c6c6c6;
  -webkit-box-shadow: 0 3px 10px #c6c6c6;
}

.vertical table.list tr th {
  padding-bottom: 4px;
  -webkit-padding-after: 4px;
  -webkit-padding-before: 2px;
}

.vertical table.list .toolbar a {
  margin-top: 0;
}

.vertical table.list .toolbar-box {
}

.vertical ul.tabs {
  background: #CACACA;
  border-bottom: 1px solid #E4E4E4;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.vertical ul.tabs li {
  border: 0;
  background: transparent url(../img/tiles-y.png) repeat-y right 0 !important;
  border-color: #E4E4E4 #CACACA #B1B1B1;
  border-style: solid;
  border-width: 1px 0;
}

.vertical ul.tabs li a {
  padding: 4px 10px 4px 20px;
}

.vertical ul.tabs li.head {
  padding: 0 20px 2px;
  background: #1B1B1B !important;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  border-color: #1B1B1B;
}

.vertical ul.tabs li:nth-child(2) a {
  background: #CACACA url(../img/tiles-y.png) repeat-y right 0;
  margin-bottom: 0;
  margin-top: -2px;
}

.vertical .tabs .current a {
  background: #fff url(../img/tiles-y.png) repeat-y right 0 !important;
  margin-bottom: 0;
  color: #000;

}

.vertical .line {
  width: 75% !important;
  float: left;
  background: #fff;
  min-height: 250px;
}

.vertical .list {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #E3E3E3;

}

.vertical .tabNavWrapper {
  width: 25% !important;
  min-width: 25% !important;
  float: left;
  background: #CACACA url(../img/tiles-y.png) repeat-y right 0 !important;
}

.vertical tbody.item tr:first-child > * {
  border-top: 1px solid #E3E3E3 !important;
}

/**
 * preislisten
 **/

table.pricelist .category,
table.pricelist .category:hover {
  background: #CACACA;
}

form.pricelistForm {
  width: 100%;
}

ul.pricelist li:not(:first-child) {
  margin-left: 30px;
}

ul.pricelist li label {
  margin-right: 10px;
}

/**
 * timesheet anpassungen
 **/
table.daily-timesheet tr.item td:first-child img {
  margin: 3px 0 0 15px;
}

button.start-stop {
  position: relative;
  min-width: 50px;
  text-transform: uppercase;
  color: #fff !important;
  padding-top: 0 !important;
  font-weight: bold;
  background: url(../img/btn-start-stop.png) repeat;
  margin-top: 0;
  height: 18px;
  width: 80px;
  background-size: 18px;
  font-size: 1.1em;
}

/**
 * Rechnungsposten
 **/
#prjInvoiceEntryForm {
  width: 100%;
}

#prjInvoiceEntryForm #prj-invoiceentry-timesheets {
  padding: 0 20px;
}

#prjInvoiceEntryForm #prj-invoiceentry-timesheets table.list {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.th-selectable {
  width: 40px;
}

/**
 * angebpotsposten edit
 **/
#prj-invoiceentry-details .selectallContainer {
  max-height: 400px;
  overflow: auto;
}

.selectallTriggerContainer {
  margin-bottom: 10px;
}

.categorieColor {
  float: left;
  margin-right: 5px;
  height: 11px;
  width: 11px;
  border: 1px solid #ccc;
}

/**
 * forecast
 **/
table.forecast td.month {
  border-left: 0 !important;
}

table.forecast td.endmonth {
  border-right: 1px solid #000;

}
