/*
 * I recently upgraded jquery chosen to version 0.13.0 and therefore decided to include its minified CSS file and to
 * write an Inform2 specific file containing OVERRIDES (see chosen.inform.css). I extracted these from the former
 * CSS file (chosen.css) that basically was the jquery chosen library version 0.9.8 with some Inform2 specific changes.
 *
 * @author Niels Garve, niels.garve.inostudio.de
 */
.chzn-container {
  font: 11px/1.4545 Arial, "Helvetica Neue", Helvetica, sans-serif;
  margin-bottom: 0.3em;
}

.chzn-container .chzn-drop {
  box-shadow: none;
}

.chzn-container-single .chzn-single {
  background-image: none;
  border-radius: 0;
  background-clip: border-box;
  box-shadow: none;
}

.chzn-container-single.chzn-disabled .chzn-single abbr:hover {
  background-position: right top;
}

.chzn-container-single .chzn-drop {
  border-radius: 0;
}

.chzn-container-multi .chzn-choices {
  background-image: none;
}

.chzn-container-multi .chzn-choices li.search-choice {
  border-radius: 0;
  background-clip: border-box;
  background-image: none;
  box-shadow: none;
}

.chzn-container .chzn-results {
  -webkit-overflow-scrolling: touch;
}

.chzn-container .chzn-results li.highlighted {
  background-color: #BFEE7D;
  background-image: none;
  color: inherit;
}

.chzn-container-active.chzn-with-drop .chzn-single {
  background-color: #fff;
  background-image: none;
}
