/*
	=== BIG FORMS =============================================================
*/

.fields {
  list-style: none;
  margin-bottom: 2em;
}

.fields > li:after {
  clear: both;
  content: "\00a0";
  display: block;
  height: 0;
  visibility: hidden;
}

.fields > li {
  max-width: 502px;
  padding-left: 180px;
  _zoom: 1;
}

/*
	ausnahmen fuer fields in .cal
	@extends .cal .overlay
*/

.fields li fieldset > .date {
  margin: 0;
  float: left;
  line-height: 1.6em;
}

.fields input.date {
  margin: 0 10px 0 0;
  float: left;
  line-height: 1.6em;
}

.fields li fieldset .date > p.description {
  margin: 0 !important;
  float: left;
  padding-right: 15px;
}

.fields li fieldset .date > input#ce_event_endtime,
.fields li fieldset .date > input#ce_event_starttime,
#ce_repeat_ends_after {
  width: 50px;
  margin-bottom: 0;
}

#calEventForm .fields li fieldset {
  margin-bottom: 0.4545em;
}

#calEventForm .checkboxes-radios.radios-horizontal label {
  float: left;
}

/*
	ausnahmen fuer fields in .adr
	@extends .adr
*/

.fields > li.adr {
  margin-left: -200px !important;
}

p.fields {
  margin-left: 0px !important;
}

.fields > li > label:first-child {
  display: block;
  float: left;
  margin-left: -180px;
  min-width: 0;
  width: 160px;
}

.fields > li > input.label-left + label {
  display: block;
  float: left;
  margin-left: -180px;
  min-width: 0;
  width: 160px;
}

.fields > li > span.left {
  display: block;
  float: left;
  margin-left: -180px;
  min-width: 0;
}

.fields > li > * {
  min-width: 480px;
  max-width: 502px;
  /* width: 480px;
  width: auto !important; // IE HACK */
}

.fields > li > button[type=button] {
  min-width: 0 !important;
}

.fields > li > fieldset > legend span {
  position: absolute;
  margin-left: -180px;
  max-width: 180px;
}

.fields > li.wide {
  padding-left: 0 !important;
}

.fields > li.wide iframe {
  height: 365px !important;
}

.fields > li.wide label {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.fields > li.wide button {
  text-indent: -9999px;
}

.fields > li > *.wide {
  min-width: 480px;
  padding-left: 20px !important;
}

.fields input.start-stop{
  margin-right: 10px;
  background-color: #d8f6c5;
}

table .fields {
  margin-bottom: 0;
}

.fields table {
  margin-bottom: 0;
  margin-left: 0;
}

.fields table label {
  font-weight: normal;
}

.fields .errors {
  clear: both;
}

.buttons {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px;
}

#adrMailingForm .buttons,
#calCalendarForm .buttons {
  margin-right: -5px;
}

.buttons .default {
  font-weight: bold;
}

.buttons button {
  margin-left: 3px;
}

.hint {
  font-style: italic;
  margin-bottom: 0;
}

div#prj-expense-transfer p.hint {
  margin-left: 0px;
}

form {
  display: -moz-inline-box; /* FF2 */
  display: inline-block;
}

.list-form {
  display: block;
  margin-bottom: 10px;
}

fieldset {
  border: none;
  position: relative;
}

form fieldset.checkboxes-radios label {
  display: block;
  margin-right: 20px;
}

form fieldset.mailings.checkboxes-radios label {
  display: inline;
}

form fieldset.checkboxes-radios label.disabled {
  text-decoration: line-through;
}

form fieldset.checkboxes-radios.compact label {
  float: left;
}

button,
input,
select,
textarea {
  margin: 0;
}

input,
select,
textarea {
  border: 1px solid #4d4d4d;
  border-color: #4d4d4d #e3e3e3 #e3e3e3 #4d4d4d;
  margin-bottom: 0.3em;
  *vertical-align: middle; /* IE7 only */
}

input[type="checkbox"],
input[type="radio"] {
  border: none;
  margin-right: 0.3em;
  min-width: 0;
  vertical-align: middle;
  width: auto;
}

label[for=ac_gender-m] {
  margin-right: 0.6em;
}

/* Rounded corners */
button,
input,
select,
textarea,
.toolbar a,
a.button {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

/* Buttons */

button,
input[type="submit"],
input[type="reset"],
input[type="button"],
a.button,
.toolbar a {
  background: #fff url(../img/tiles-buttons.png) no-repeat scroll 0 center;
  border: 1px solid #e3e3e3;
  border-color: #e3e3e3 #4d4d4d #4d4d4d #e3e3e3;
  color: #000 !important;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none !important;
}

button.active {
  background: url("../img/tiles-buttons.png") no-repeat scroll 0 center #CCCCCC;
  border-color: #6E6E6E #F1F1F1 #F1F1F1 #6E6E6E;
  border-style: solid;
  border-width: 1px;
  color: #FFFFFF !important;
}

.toolbar a {
  padding: 0;
}

button:active,
input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
a.button:active,
.toolbar a:active {
  background-position: 1px center;
  border-color: #4d4d4d #e3e3e3 #e3e3e3 #4d4d4d;
}

button[disabled]:active,
input[type="submit"][disabled]:active,
input[type="reset"][disabled]:active,
input[type="button"][disabled]:active,
a.button.disabled:active,
#global-data + ul a.disabled[rel="add"]:active,
#global-data + ul a.disabled[rel="new"]:active,
table.list .editing a[rel]:active {
  background-position: 0 center;
  border-color: #eee #666 #666 #eee;
}

button.rel {
  height: 18px;
  padding: 0;
  width: 18px;
  text-indent: 20px;
}

button.remove,
button.add {
  text-indent: -9999px;
}

/*
	=== SPECIAL FORM WIDGETS ==================================================
*/
form#prjTimeSheetForm {
  display: inherit;
}

select.optgroup-cascade,
select.optgroup-cascade + select {
  min-width: 0 !important;
}

.with-unit {
  min-width: 0 !important;
}

.with-unit + .hint {
  display: -moz-inline-box;
  display: inline-block;
  margin-left: 0;
  min-width: 0;
}

.nohint ~ .hint {
  font-style: normal;
}

/*
	Relationship: two items in a circular relationship.
*/
.relationship-fields {
  margin: 0 20px 1.4545em;
}

.relationship-fields ul {
  list-style: none;
  margin: 0;
}

.relationship-fields li {
  float: left;
  padding-right: 20px;
}

.relationship-fields li > * {
  min-width: 280px;
  /* width: 280px;
  width: auto !important; // IE HACK */
}

.relationship-fields li > textarea {
  width: 280px;
  min-width: 280px;
  height: 18px;
  padding: 1px 0;
}

.relationship-fields #acm_name {
  height: 18px;
  margin-bottom: 7px;
}

.relationship-fields li:first-child,
.relationship-fields li:first-child + li + li {
  padding-left: 20px;
}

.relationship-fields li:first-child {
  background: url(../img/relation-arrow-left-top.png) left center no-repeat;
}

.relationship-fields li:first-child + li {
  background: url(../img/relation-arrow-right-top.png) right center no-repeat;
  float: right;
}

.relationship-fields li:first-child + li + li {
  background: url(../img/relation-arrow-left-bottom.png) left center no-repeat;
  clear: both;
}

.relationship-fields li:first-child + li + li + li {
  background: url(../img/relation-arrow-right-bottom.png) right center no-repeat;
  float: right;
}

.relationship-fields label {
  display: none;
}

.relationship-fields ul.errors {
  left: 20px;
  position: absolute;
}

/*
	--- Rating ---
*/
.scripted .rate {
}

.scripted .rate label {
  background: url(../img/icons/16/icn_rating_off.png) no-repeat left top;
  height: 0;
  overflow: hidden;
  position: relative;
  padding: 16px 0 0;
  top: -1px;
  width: 20px;
}

.scripted .rate label.active {
  background-image: url(../img/icons/16/icn_rating_on.png);
}

.scripted .rate label input {
  display: none;
}

/*
 --- pickers ---
*/

/*
	--- file picker inputs ---
*/
html.scripted input.filepicker {
  display: none;
}

.picked-files ol > li {
  padding: 0.5em 0;
  min-width: 0;
}

.picked-files ol li img {
  vertical-align: middle;
  margin-right: 0.5em;
}

/*
	--- custom color picker ---
*/
.custom-color-picker {
  background: white;
  border: 1px solid #ddd;
  list-style: none;
  margin: -0.5em 0 0;
  padding: 3px 3px 0 0;
  position: absolute;
  width: 100px;
  z-index: 101;
}

.custom-color-picker li {
  float: left;
  padding-left: 3px;
  padding-bottom: 3px;
}

.custom-color-picker input {
  position: absolute;
  left: -1000em;
  top: -1000em;
}

.custom-color-picker label {
  border: 1px solid #ddd;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 15px;
  width: 15px;
}

.custom-color-picker .no-color {
  clear: left;
}

.custom-color-picker .no-color label {
  width: 95px;
}

.custom-color-picker :checked + label,
.custom-color-picker .checked + label,
.custom-color-picker [checked] + label {
  border-color: #000;
}

input.default-value {
  color: #7f7f7f;
}

/* --- repeatables --- */
.repeatable {
  display: inline-block;
  list-style: none;
}

.repeatable > * {
  padding-right: 60px;
  position: relative;
}

.repeatable button.rel.add {
  visibility: hidden;
}

.repeatable .last-visible button.rel.add {
  visibility: visible;
}

/* --- edit files in folders --- */
.batch_edit {
  padding: 10px 25px !important;
  background-color: #fff;
  border-bottom: 2px solid #000;
  margin-bottom: 10px;
  -moz-box-shadow: 0 3px 10px #B1B1B1;
}

.batch_edit input#batcheditall,
.batch_edit input#batcheditallTasks,
.batch-edit select#folder {
  margin-right: 5px !important;
}

/*
	=== PAGING ================================================================
*/
.paging {
  overflow: hidden;
}

.paging,
.paging input {
  color: #666;
}

.paging form {
  float: left;
  margin-right: 0.3em;
}

.paging input {
  width: 2em;
  text-align: center;
}

.paging input,
.paging button {
  vertical-align: baseline;
}

/*
	=== MAIN TOOLBAR FORMS ===
*/
.tools button,
.tools input,
.tools select,
.tools textarea {
  color: #fff !important;
  background-position: -300px center;
  background-color: #444;
  border-color: #000 #666 #666 #000;
  line-height: 1.4545em;
  vertical-align: baseline;
}

.tools input {
  max-width: 100px;
}

.tools input.date {
  max-width: 70px;
}

.tools .extra-label {
  color: #B1B1B1;
}

.tools input[type="checkbox"],
.tools input[type="radio"] {
  vertical-align: text-bottom;
}

.tools button,
.tools input[type="button"],
.tools input[type="reset"] {
  border-color: #666 #000 #000 #666;
}

.tools button:active:hover,
.tools input[type="button"]:active:hover,
.tools input[type="reset"]:active:hover {
  border-color: #000 #666 #666 #000;
}

.tools form {
  float: left;
}

.list-filter label {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.list-filter label[for=mdb-all-folders],
.list-filter label[for=wiki-all-folders],
.list-filter label[for=letter-all-folders] {
  position: relative;
  top: 0;
  left: 0;
}

.list-filter input,
.list-filter select,
.list-filter button {
  margin-right: 4px;
}

.list-filter input:not([type=checkbox]),
.list-filter select {
  width: 100px;
}

/* absetzen des editforms bei prj/edittimesheet */
h2#list_day {
  border-top: 1px solid #ddd !important;
  margin: 0 !important;
  padding: 0.4545em 20px !important;
  background-color: #eee;
}

#settingsForm #prj-services {
  width: 730px;
}

label[for=pj_type-out] {
  margin-right: 10px;
}

fieldset.aa_ac_fk {
  margin-top: 22px;
}

/* aconto und forecast listen */
#prj-master-dependencies {
  overflow: hidden;
}

#prj-master-dependencies .dependencies-list {
  width: 50%;
  float: left;
}

#prj-master-dependencies .dependencies-list .fields > li {
  padding-left: 0;
}

#prj-master-dependencies .dependencies-list .fields > li ol > li {
  margin-bottom: 0.4545em;
}

#serviceListDetailForm .fields p {
  width: 480px; /* otherwise min-width within ".fields > li > *"-block doesn't work TODO right? */
  margin-left: 0;
}

#prjExpenseForm .approval.list {
  margin-bottom: 0.3em !important; /* (siehe '.bd table.list'-Anweisung in obj.css) */
  border-width: 1px;
  border-top: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

#prjExpenseForm .approval.list table > tbody > tr > th {
  padding-left: 0;
}

#prjExpenseForm .approval.list table > tbody > tr > td:first-of-type {
  padding-right: 20px;
}

/* Ausnahme fuer Inform Rechte */
#sys-usergroups {
  max-width: 100%;
}
