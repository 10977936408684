/*
	=== BRANDING ==============================================================
*/
#inform-logo {
  background: url(../img/inform2-logo.png) no-repeat left top;
  height: 26px;
  margin: 20px;
  text-indent: -9999em;
  width: 196px;
}

#inform-version {
  float: left;
  width: 120px;
}

/*
	=== MAIN LAYOUT ELEMENTS ==================================================
*/
#main {
  background: #f8f8f8 url(../img/tiles-y.png) repeat-y 243px 0;
  overflow: hidden;
}

.head,
.main,
.foot {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.main {
  background: #fff;
  color: #000;
  padding: 1.4545em 0;
  min-height: 600px;
}

.foot {
  padding: 0.7273em 0;
}

.tools {
  background: #1b1b1b url(../img/tiles-x.png) repeat-x 0 0;
  color: #fff;
  height: 36px;
  line-height: 32px;
  margin: -1.4545em 0 2.5em;
  white-space: nowrap;
}

/*
	@extends .tools
*/
.tools-sidebar {
  background-position: 0 -36px;
  border-right: 1px solid #1b1b1b;
  margin: 0;
}

#inform-login {
  position: absolute;
  right: 0;
  top: 20px;
  margin-right: 10px;
}

#footer-links {
  float: right;
  margin: 0 10px 0 0;
}

#inform-login strong {
  color: #fff;
}

#date-time {
  width: 140px;
  margin-bottom: 10px;
}

#date-time > * {
  padding: 0 0 0 60px;
}

#date-time-date {
  margin-top: -9px;
  padding-top: 18px;
}

#date-time-day {
  color: #bfee7d;
  float: left;
  font-weight: bold;
  margin-left: -40px;
}

#date-time-time {
  color: #b1b1b1;
  margin-bottom: 0.2323em;
}

/*
	=== DASHBOARD =============================================================
*/

#dashboard {
  list-style: none;
  margin: 0;
  padding: 0 10px;
}

#dashboard > * {
  float: left;
  min-height: 2.9091em;
  position: relative;
  width: 62%;
}

#dashboard #message {
  margin: 0 10px 1.4545em;
  padding: 0;
}

#dashboard > * > * {
  list-style: none;
  margin: 0;
  padding: 0 10px;
  min-height: 80px;
}

#dashboard-container-aside {
  float: right;
  margin-right: 0;
  width: 38%;
}

#dashboard-container-sub1,
#dashboard-container-sub2 {
  width: 31%;
}

#dashboard-container-sub1 {
  clear: left;
}
