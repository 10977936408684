/*
	=== BLOCK LEVEL ELEMENTS RESET ============================================
*/
html,
body,
address,
blockquote,
del,
div,
dl,
fieldset,
form,
h1,
h2,
h3,
h4,
h5
h6,
hr,
legend,
ins,
noscript,
ol,
p,
pre,
table,
ul {
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  padding: 0;
  list-style: none;
  vertical-align: top;
}

/*
	=== INLINE LEVEL ELEMENTS RESET ===========================================
*/
a,
abbr,
acronym,
big,
cite,
del,
dfn,
ins,
kbd,
label,
q,
samp,
small,
span,
var {
  border: none;
  font-size: 1em;
  text-decoration: none;
}

q:after,
q:before {
  quotes: none;
  content: "";
}

/* --- no borders on linked images --- */
a img {
  border: none;
}

/*
	=== CLEARFIX - These elements clear all floats they contain ============
*/
.editing:after
{
  clear: both;
  content: "\00a0";
  display: block;
  height: 0;
  visibility: hidden;
}

* + html .editing:after {
  /* IE 7: doesn't support generated content */
  min-height: 1%; /* triggers "hasLayout" */
}
