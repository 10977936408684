.re-item {
  position: absolute;
}

.re-item {
  border: 1px solid #E3E3E3;
  padding-left: 4px;
  color: #000;
  overflow: hidden;
  height: 16px;
}

.re-planning .re-title {

}

.re-day {
  position: absolute;
  width: 28px;
  border-right: 1px solid #E3E3E3;
  text-align: center;
  height: 18px;
}

.re-today {
  background: #FFC;
}

table.resources tr.res-unit {
  background: #e3e3e3;
  text-transform: uppercase;
  font-weight: 700;

  &:hover {
    background: #e3e3e3;
  }

  .item-title {
    padding-left: 7px;
  }
}

table.resources .item-title {
  padding: 4px 0;
}

table.resources .item {
  background: #fff url(../img/tiles-y.png) repeat-y right 0;
  padding-top: 1px;
}

table.resources .re-task {
}

table.resources .item {
  border-top: 1px solid #E3E3E3 !important;
}

table.resources .ghosts {
  position: relative;
}

table.resources .ghosts .re-planning {
  background-color: rgba(99, 238, 227, 0.3) !important;
  border: 1px solid rgb(66, 174, 165) !important;
}

table.resources .ghosts .re-absence {
  background-color: rgba(227, 227, 227, 0.7) !important;
  border: 1px solid rgb(166, 166, 166) !important;
  z-index: 9;
  background-image: none;
}

table.resources tbody tr > td, table.resources tbody tr > th {
  padding: 0;
}

#resSlider .ui-slider-handle {
  border: 1px solid #CCC;
  background: #F6F6F6 url('../images/ui-bg_glass_100_f6f6f6_1x400.png') 50% 50% repeat-x !important;
  font-weight: bold;
  color: #1C94C4;
}

#resSlider {
  margin: 30px 30px;
}

.ressourceScroll {
  position: relative;
  height: 18px;
  left: 0;
}

.ressourceScrollWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 18px;
}

.re-milestone {
  background: transparent url(../img/icons/icn_milestone.png) no-repeat 7px 0;
  background-size: 16px;
  border: 0;
  width: 28px;
}

table.resources .ghosts .re-milestone {
  background: transparent url(../img/icons/icn_milestone_ghost.png) no-repeat 9px 0;
  background-size: 12px;
  margin-top: 3px;
}

table.resources a.new-ressource-button {
  background-position: -2px -2px;
  display: none;
  height: 11px;
  margin-left: 5px;
  margin-top: -6px;
  width: 11px;
}

table.resources tr:hover a.new-ressource-button {
  position: absolute;
  top: 7px;
  right: 20px;
  display: block;
}

table.resources th:hover a.new-ressource-button {
  display: block;
}

.ressourceScrollWrapper {
  overflow: hidden !important;
}

.ghosts {
  overflow: hidden !important;
}

.derHelper {
  position: absolute;
  height: 1px;
  width: 2928px;
}

.list.resources.expandable-tbodies {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

form#ressourceForm dd {
  margin-left: 0;
}

form#ressourceForm dd textarea {
  min-width: 480px;
}

.re-no-entry .re-day .day,
.re-unscheduled .re-day .day,
.re-plan .re-day .day {
  display: none;
}

.re-absence {
  cursor: default !important;
}

form#ressourceForm .multiAutoCompleter {
  margin-top: 5px;
}

form#ressourceForm .multiAutoCompleter label {
  font-weight: normal;
}

tr.task .dependencyToggle {
  display: none;
  position: absolute;
  right: 8px;
  top: 3px;
  height: 9px;
  width: 19px;
  background-image: url(../img/icons/icn-chain.png);
  background-position: 0 9px;
}

tr.task:hover .dependencyToggle {
  display: block;
}

tr.task .dependencyToggle.active {
  display: block;
  background-position: 0 0px;
  top: 5px;
  right: 7px;
}

.no-edit-mode tr.task .dependencyToggle,
.no-edit-mode tr.task:hover .dependencyToggle {
  display: none !important;
}

.absence .re-absence.non-allday {
  background-image: url(../img/res-non-allday-bg.png);
  background-position: 0;
}

.res-user .item-title span.relative,
.res-user .item.task span.relative {
  display: block;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  white-space: nowrap;
  padding-right: 20px;
}

.ghosts .weekend {
  background-color: rgba(227, 227, 227, 0.7) !important;
  border: 1px solid #A6A6A6 !important;
  z-index: 9;
  width: 27px;
  height: 16px;
}

.ghosts .weekend .day {
  display: none;
}
